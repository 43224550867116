import React, { useState, useEffect} from 'react';
import { GridColumnMenu, gridFilteredSortedRowIdsSelector, gridVisibleColumnFieldsSelector, useGridApiContext, GridToolbarContainer } from '@mui/x-data-grid';
import { Alert, Box, Button, Grid, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import * as moment from 'moment';
import CustomToolbar from '../Excel';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FunctionsIcon from '@mui/icons-material/Functions';
import useAuthStore from '../../auth/authStore';
import config from '../../config.json';
import fetchContractSums from './fetchContractSums';
import AggregationTable from './AggregationTable';
import MainCard from '../MainCard';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    DoughnutController
  } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, DoughnutController);

const acceptedHeaders = ["Project",
"NTP",
"Estimated Cost (NTP)",
"Fixed Markup"];

const fieldNames = ['contract_id', 'work_order_number', 'work_order_title', 'proposal_status', 'NTP_date',
'NTP_amount', 'estimated_cost_after_ntp']

const fields = {id: null, contract_id: '', work_order_number: '',work_order_title: '',proposal_status: '',NTP_date:null, NTP_amount: null, estimated_cost_after_ntp: null}

const fieldLabels = [{name: 'contract_id', label: 'Contract ID' }, {name: 'work_order_number', label: 'WO #' }, {name: 'work_order_title', label: 'WO Title' }, 
{name: 'estimator', label: 'Estimator' }, {name: 'project_manager', label: 'Project Manager' }, {name: 'RFP', label: 'RFP' }, {name: 'job_walk', label: 'Job Walk' }, {name: 'proposal_due_date', label: 'Proposal Due Date' }, {name: 'proposal_status', label: 'Proposal Status' }, {name: 'proposal_date_submitted', label: 'Date Submitted' }, {name: 'inquiry_subcon_date', label: 'Inquiry Subcon Date' }, 
{name: 'inquiry_subcon_status', label: 'Inquiry Subcon Status'}, {name: 'budget_status', label: 'Budget Status' }, 
{name: 'reviewer', label: 'Reviewer' }, {name: 'review_date', label: 'Review Date'},
{name: "NTP_date", label: 'NTP Date'},
{name: "NTP_amount", label: 'NTP Amount'},
{name: "estimated_cost_after_ntp", label: 'Estimated Cost (NTP)'}
]

const dateFields = ["RFP", "job_walk",  "proposal_due_date", "proposal_date_submitted", "inquiry_subcon_date", "review_date","NTP_date"];
const peopleFields = ["estimator", "project_manager",  "reviewer"];
const statusFields = ["proposal_status", "budget_status",  "inquiry_subcon_status", "proposal_status"];
const booleanFields = [];
const budgetStatusFields = ["budget_status"];

function RowAddTextField({ name, label, autoFocus, value, onChange }) {
    return (
      <TextField
        autoFocus={autoFocus}
        margin="dense"
        name={name}
        label={label}
        type="text"
        fullWidth
        value={value || ''}
        onChange={onChange}
      />
    );
  }

function TableComponent() {
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [editedRows, setEditedRows] = useState({});
    const [peopleMapping, setPeopleMapping] = useState([]);
    const [peopleOptions, setPeopleOptions] = useState([]);
    const [snackbar, setSnackbar] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [numprojects, setNumProjects] = useState(0);
    const [doughnutSlot, setDoughnutSlot] = useState('revenue');

    const [contractSums, setContractSums] = useState(null)

    const { username } = useAuthStore();

    useEffect(() => {
        const fetchData = async () => {
            try{
        const projectsResponse = await axios.get(`${config.apiBaseUrl}/api/projects/`, {withCredentials: true});
        const peopleResponse = await axios.get(`${config.apiBaseUrl}/api/people/`, {withCredentials: true});

        const projects = projectsResponse.data;
        const people = peopleResponse.data;

        const formattedProjects = projects
        .filter(project => project['proposal_status'] === 'NTP')
        .map((project) => {
            const formattedProject = { id: project.id };
    
            Object.keys(project).forEach((key) => {
                if (dateFields.includes(key)) {
                    // Check if the value is null or not a date before converting
                    formattedProject[key] = project[key] ? new Date(project[key]) : project[key];
                } else if (peopleFields.includes(key)) {
                    // If it's a peopleField, replace null with an empty string
                    formattedProject[key] = project[key] === null ? '' : project[key];
                } else {
                    // Just copy the value
                    formattedProject[key] = project[key];
                }
            });
            return formattedProject;
        });

        const formattedPeople = people.map((person) => ({
        id: person.id,
        ...person,
        }));

        const peopleMapping = {'':''};
        const peopleDropdownOptions = [{value: '', label: 'N/A'}];

        formattedPeople.forEach(person => {
        peopleMapping[person.id] = `${person.first_name} ${person.last_name}`;
        peopleDropdownOptions.push({value: person.id, label: `${person.first_name} ${person.last_name}`});
        });
        
        setNumProjects(formattedProjects.length);
        setRows(formattedProjects);
        setPeopleMapping(peopleMapping);
        setPeopleOptions(peopleDropdownOptions);
        setLoading(false);

        fetchContractSums(config, setContractSums);
        } catch (error) {
            console.error('Error fetching subtotal data: ', error);
    };
};
    
        fetchData();
      }, []);

    useEffect(() => {
    }, [editedRows]);

    const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    });
    
    const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    cellClassName: 'font-tabular-nums',
    };

    const percentage = {
        type: 'number',
        width: 130,
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }
            return `${params.value.toLocaleString()} %`
        },
        cellClassName: 'font-tabular-nums'
        };

    const statusOptions = [
        { value: 'IN_PROGRESS', label: 'In Progress' },
        { value: 'SUBMITTED', label: 'Submitted' },
        { value: 'ON_HOLD', label: 'On Hold' },
        { value: 'CANCELLED', label: "Cancelled"},
        { value: 'NTP', label: 'NTP' },
        { value: null, label: ''}
    ]

    const budgetStatusOptions = [
        { value: 'NOT_READY', label: 'Not Ready' },
        { value: 'READY', label: 'Ready' },
        { value: null, label: ''}
    ]

    const columns = [
        { 
            headerName: "ID", 
            field: "id"
        },
        { 
            headerName: "Contract ID", 
            field: "contract_id",
            minWidth: 125,
            editable: false
        },
        { 
            headerName: "WO #", 
            field: "work_order_number",
            minWidth: 165,
            editable: false
        },
        { 
            headerName: "WO Title", 
            field: "work_order_title",
            minWidth: 200,
            editable: false  
        },
        { 
            headerName: "NTP Date", 
            field: "NTP_date",
            type: 'date',
            minWidth: 125,
            valueFormatter: function (params) {
                if (params.value === null || params.value === '') {
                    return ''; // return an empty string for null or blank dates
                }
                return moment(params.value).isValid() ? moment(params.value).format('MM/DD/YYYY') : '';
            },
            editable: false
        },
        { 
            headerName: "NTP Amount", 
            field: "NTP_amount",
            type: 'number',
            minWidth: 125,
            editable: false,
            flex: 1,
            editable: true,
            ...usdPrice
        },
        { 
            headerName: "Estimated Cost (NTP)", 
            field: "estimated_cost_after_ntp",
            type: 'number',
            minWidth: 200,
            editable: false,
            flex: 1,
            editable: true,
            ...usdPrice
        },
        { 
            headerName: "Fixed Markup", 
            field: "fixed_markup",
            minWidth: 125,
            valueGetter: getFixedMarkup,
            flex: 1,
            ...usdPrice
        },
        { 
            headerName: "Markup %", 
            field: "fixed_markup_perc",
            minWidth: 125,
            valueGetter: getFixedMarkupPercentage,
            flex: 1,
            // renderCell: (params) => {
            //     const backgroundColor = params.value > 15 ? green[500] : red[500];
            //     {return (
            //         <Box
            //           sx={{
            //             backgroundColor,
            //             width: "100%",
            //             height: "100%",
            //             display: 'flex',
            //             alignItems: 'center',
            //             justifyContent: 'center'
            //           }}
            //         >
            //           {params.value} %
            //         </Box>
            //       )}
            // },
            ...percentage,
        },
        { 
            headerName: "Gross Profit %", 
            field: "gross_profit_after_ntp",
            minWidth: 125,
            valueGetter: getNTPGrossProfit,
            flex: 1,
            ...percentage
        }

    ];    
    function extractPieChartValues(contractSums, chartKey) {
        let markupValues = [];
      
        // Iterate over the first level of the object
        for (const key in contractSums) {
          if (contractSums.hasOwnProperty(key)) {
            const subObject = contractSums[key];
      
            // Iterate over the second level of the object
            for (const subKey in subObject) {
              if (subObject.hasOwnProperty(subKey) && subKey === chartKey) {
                markupValues.push(subObject[subKey]);
              }
            }
          }
        }
      
        return markupValues;
      }
    
    function aggregateAndSortData(contractSums, chartKey) {
        // Extract markup values
        const markupValues = extractPieChartValues(contractSums, chartKey);

        // Combine keys with their corresponding markup values
        const entries = Object.keys(contractSums).map((key, index) => {
            return { label: key, value: markupValues[index] };
        });
        
        // Sort the entries based on value
        entries.sort((a, b) => b.value - a.value);
      
        // Aggregate smaller values into an "Other" category
        const keybasedthreshold = { 'total_ntp_amount': 1800000, 'total_estimated_cost': 1800000,
        'markup': 500000, 'markup_percentage': 30}

        let otherThreshold = keybasedthreshold[chartKey]; // Define a threshold for bucketing smaller values
        let otherValue = 0;
        let filteredEntries = entries.filter(entry => {
          if (entry.value < otherThreshold) {
            otherValue += entry.value;
            return false;
          }
          return true;
        });
      
        if (otherValue > 0) {
          filteredEntries.push({ label: 'Other <500,000', value: otherValue });
        }
      
        // Separate the labels and values
        const labels = filteredEntries.map(entry => entry.label);
        const values = filteredEntries.map(entry => entry.value);
        
        return { labels, values };
    }
    
    const colors = [
        '#455A64', // Dark Blue Grey
        '#5D4037', // Dark Brown
        '#7B1FA2', // Dark Purple
        '#D32F2F', // Dark Red
        '#1976D2', // Dark Blue
        '#388E3C', // Dark Green
        '#F57C00', // Dark Orange
        '#C2185B', // Dark Pink
        '#7B1FA2', // Darker Purple
        '#303F9F', // Dark Indigo
        '#0288D1', // Dark Light Blue
        '#00796B', // Dark Teal
        '#689F38', // Dark Lime
        '#FBC02D', // Dark Yellow
        '#5D4037', // Darker Brown
        '#7B1FA2', // Deep Purple
        '#F57C00', // Deep Orange
        '#C2185B', // Deep Pink
        '#303F9F', // Deep Blue
        '#455A64', // Deep Blue Grey
    ];

    const colorArray = contractSums ? 
    Object.keys(contractSums).map((_, index) => colors[index % colors.length]) : 
    [];

    // const getDataForDoughnut = () => {
    //     if (!contractSums) {
    //         return { labels: ['None'], values: [0] }; // Return default values if data is not available
    //       }
    //     switch (doughnutSlot) {
    //       case 'revenue':
    //         return aggregateAndSortData(contractSums, 'markup'); // your revenue data
    //       case 'cost':
    //         return aggregateAndSortData(contractSums, 'markup'); // your cost data
    //       case 'markup':
    //         return aggregateAndSortData(contractSums, 'markup'); // your markup data
    //       case 'markup_perc':
    //         return aggregateAndSortData(contractSums, 'markup'); // your markup percentage data
    //       default:
    //         return aggregateAndSortData(contractSums, 'markup');
    //     }
    //   };    

      const getDataForDoughnut = () => {
        if (!contractSums) {
            return { labels: [], values: [] }; // Return default values if data is not available
          }
        switch (doughnutSlot) {
          case 'revenue':
            return aggregateAndSortData(contractSums, 'total_ntp_amount'); // your revenue data
          case 'cost':
            return aggregateAndSortData(contractSums, 'total_estimated_cost'); // your cost data
          case 'markup':
            return aggregateAndSortData(contractSums, 'markup'); // your markup data
          default:
            return aggregateAndSortData(contractSums, 'total_ntp_amount');
        }
      };   

    const PieData = {
        labels: getDataForDoughnut()['labels'],
        datasets: [{
            data: getDataForDoughnut()['values'],
            backgroundColor: colorArray,
            hoverOffset: 4
        }],
    }

    // const PieData = {
    //     labels: sortedMarkupPieData['labels'],
    //     datasets: [{
    //         label: 'Profit Breakdown',
    //         data: sortedMarkupPieData['values'],
    //         backgroundColor: colorArray,
    //         hoverOffset: 4
    //     }],
    // }

    const handleCloseSnackbar = () => setSnackbar(null);

    const handleFileLoaded = async (data) => {

        const reversedPeopleMapping = Object.fromEntries(
            Object.entries(peopleMapping).map(([key, value]) => [value, key])
          );

        const statusMapping = {'': null,
            'In Progress':'IN_PROGRESS',
            'Submitted':'SUBMITTED',
            'On Hold':'ON_HOLD',
            'Cancelled': "CANCELLED",
            'NTP':'NTP' }

        const acceptedHeaders = ["Contract ID",
        "WO #",
        "WO Title",
        "Proposal Status",
        "Estimator",
        "PM", 
        "RFP",
        "Job Walk", 
        "Proposal Date",
        "Submit Date",
        "Inquiry Subcon Date",
        "Budget Status", 
        "Reviewer",
        "Review Date",
        "Proposal Amount", 
        "Estimated Cost",
        "NTP Date",
        "NTP Amount",
        "Estimated Cost (NTP)",
        "Notes",];

        const headerToFieldMap = Object.fromEntries(
            acceptedHeaders.map((header, index) => [header, fieldNames[index]])
          );

        if (!data[0]) {
            setSnackbar({ children: 'Invalid file format: Missing headers', severity: 'error' });
            return;
        }

        // clean end of line \r values
        let cleanedHeaders = data[0].map(header => header.replace(/\r?\n|\r|\$/g, ''));
        let cleanedData = data.map(row => 
            Array.from({ length: row.length }, (_, i) => {
                const field = row[i];
                return field === undefined ? null : 
                       (typeof field === 'string' ? field.replace(/\r?\n|\r|\$/g, '') : field);
            })
        );

       // Create a list of valid header indices
        const validIndices = cleanedHeaders.map((header, index) => acceptedHeaders.includes(header) ? index : null).filter(index => index !== null);
        const validFieldNames = cleanedHeaders.map(header => headerToFieldMap[header]).filter(Boolean);

        // Use validIndices to filter both headers and data
        cleanedHeaders = cleanedHeaders.filter((_, index) => validIndices.includes(index));
        cleanedData = cleanedData.map(row => row.filter((_, index) => validIndices.includes(index)));

        // Check if any valid headers are left
        if (cleanedHeaders.length === 0) {
            setSnackbar({ children: 'Invalid file format: No matching columns found', severity: 'error' });
            return;
        }

        // Exclude the header row and zip rows into dictionaries
        const payload = cleanedData.slice(1).map(row => {
            return Object.fromEntries(row.map((cell, index) => [validFieldNames[index], cell]));
        });

        const convertDateFormat = (dateStr) => {
            return moment(dateStr).format('YYYY-MM-DD');
        };

        payload.forEach((row) => {
            statusFields.forEach(field=> {
                if (row[field] === "" || row[field] == null) {
                    row[field] = "";
                  }
                else {
                    row[field] = statusMapping[row[field]]
                }
            });
            peopleFields.forEach(field=> {
                if (row[field]) {
                    row[field] = reversedPeopleMapping[row[field]];
                  }
                });
            dateFields.forEach(field => {
                if (row[field]) {
                    row[field] = convertDateFormat(row[field]);
                }
            });
        });

        try {
            const response = await axios.post(`${config.apiBaseUrl}/api/projects/upload_projects/`, JSON.stringify(payload), {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const highestId = Math.max(...rows.map((row) => row.id), 0);
            const newRows = response.data.new_rows.map((item, index) => ({
                id: highestId + index + 1,
                ...item,
            }));
    
            setRows((prev) => [
                ...prev,
                ...newRows
            ]);
    
            setSnackbar({ children: 'Successfully created new rows', severity: 'success' });
    
        } catch (error) {
            setSnackbar({ children: 'Submit went wrong', severity: 'error' });
            console.error('Error:', error);
        }
    };

    function getEstimatedAmount(params) {
        return params.row.estimated_prop_amt;
      }


    function getEstimatedMarkup(params) {
        return getEstimatedAmount(params) - params.row.estimated_cost
        }
    
    function getFixedMarkup(params) {
        return params.row.NTP_amount - params.row.estimated_cost_after_ntp;
    }

    function getFixedMarkupPercentage(params) {
        const estimatedMarkup = getFixedMarkup(params);
        return ((estimatedMarkup / params.row.estimated_cost_after_ntp) * 100).toFixed(2);
    }

    function getNTPGrossProfit(params) {
        const estimatedAmount = params.row.NTP_amount
        const estimatedMarkup = getFixedMarkup(params);
        return ((estimatedMarkup / estimatedAmount) * 100).toFixed(2);
    }
    
    function AggregationMenuItem(props) {
        const { aggregationHandler, columnName } = props;
        return (
            <MenuItem onClick={() => aggregationHandler(columnName)}>
            <ListItemIcon>
                <FunctionsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{`Toggle Aggregation`}</ListItemText>
            </MenuItem>
        );
    }

    function CustomColumnMenu(props) {
        return (
          <GridColumnMenu
            {...props}
            slots={{
                columnMenuUserItem: AggregationMenuItem
              }}
              slotProps={{
                columnMenuUserItem: {
                  displayOrder: 15,
                  columnName: props.colDef.field,
                  aggregationHandler: handleToggleAggregation
                },
              }}
            />
          );
        }

      const handleToggleAggregation = (field) => {
        setSelectedColumns((prevSelectedColumns) =>
          prevSelectedColumns.includes(field)
            ? prevSelectedColumns.filter((col) => col !== field)
            : [...prevSelectedColumns, field]
        );
      };
      

    const calculateSum = (rows, field) => {
        return rows.reduce((acc, row) => acc + parseFloat(row[field] || 0), 0);
    };
    
    const AggregatorToolbar = () => {
        const apiRef = useGridApiContext();
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const visibleColumns = gridVisibleColumnFieldsSelector(apiRef);

        const filteredRows = filteredSortedRowIds.map((id) => {
            const row = apiRef.current.getRow(id);
            return Object.keys(row)
              .filter((key) => visibleColumns.includes(key))
              .reduce((obj, key) => {
                obj[key] = row[key];
                return obj;
              }, {});
          });

        const sums = selectedColumns.reduce((acc, column) => {
            acc[column] = calculateSum(filteredRows, column);
            return acc;
          }, {}); // Using the calculateSum function
    
    return (
        <GridToolbarContainer style={{ backgroundColor: '#F1F1F1'}}>
      <Box display="flex" justifyContent="space-between" width="100%">
        {/* First toolbar section (e.g., your existing toolbar customizations) */}
        </Box>
        {/* Second row with the aggregations */}
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            {visibleColumns.map((column) => (
            <Box key={column} flexBasis={0} flexGrow={1} maxWidth="100%">
                {selectedColumns.includes(column) && (
                <Typography variant="body2">
                    {`Sum of ${column}: ${sums[column]}`}
                </Typography>
                )}
            </Box>
            ))}
        </Box>
    </GridToolbarContainer>
  );
};


// STYLING
    const totalPieValue = PieData.datasets[0].data.reduce((acc, cur) => acc + cur, 0);
    const pieChartOptions = {
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                const label = context.label || '';
                const value = context.parsed;
                const percentage = ((value / totalPieValue) * 100).toFixed(2); // Two decimal places
                return `${label}: ${percentage}%`;
              }
            }
          },
          legend: {
            display: true,
            position:'right',
            labels: {
                boxWidth: 20, // Smaller box width
                padding: 7, // Less padding
                font: {
                    size: 11, // Smaller font size
                }
            }
          },
        },
        // ... any other options you want to set ...
      };

    const theme = createTheme({
        palette: {
          neutral: {
            main: '#64748B',
            contrastText: '#fff',
          },
          secondary: {
            light: '#C5E3EC',
            main: '#1AA7EC',
            dark: '#AADDEC',
            contrastText: '#000',
          },
          primary: {
            main: '#8c8c8c'
          }
        },
        typography: {
            fontFamily: [
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(','),
          },
      });
    if (loading) return (<ThemeProvider theme={theme}>
        <Box sx={{display: 'flex'}}
        minHeight="50vh" alignItems="center"
        justifyContent="center">
        <CircularProgress sx = {{color:'secondary'}}/>
        </Box>
        </ThemeProvider>
        );     

    return (
        <>
        <Grid container rowSpacing={4.5} columnSpacing={1}>
        <ThemeProvider theme={theme}>
            {/* Main grid divided into two parts: Table and Charts */}
            <Grid item xs={12} md={7} lg={7}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} style={{ marginRight: '16px' }} > {/* Full width and some margin */}
                    <Typography style={{ fontWeight: 400,  fontSize: '1.8rem', fontFamily: 'Inter' }} sx={{ mt: 4, ml: 2 }}>
                        Contract Subtotals
                    </Typography>
                    <AggregationTable subtotals={contractSums} />
                    </Grid>
                </Grid>
            </Grid>

            {/* Right side container for Doughnut Charts */}
            <Grid item xs={12} md={5} lg={5} sx={{ paddingRight: 3 }}>
                {/* Doughnut Chart 1 with its title */}
                    <Grid item>
                    <Typography style={{ fontWeight: 400,  fontSize: '1.8rem', fontFamily: 'Inter' }} sx={{ mt: 4 }}>
                        Profit Distribution
                    </Typography>
                    </Grid>
                    <Grid item>
                        <Stack direction="row" alignItems="center" spacing={0}>
                            <Button
                                size="small"
                                style={{ minWidth: '80px' }}
                                onClick={() => setDoughnutSlot('revenue')}
                                color={doughnutSlot === 'revenue' ? 'secondary' : 'primary'}
                                variant={doughnutSlot === 'revenue' ? 'outlined' : 'text'}
                            >
                                Revenue
                            </Button>
                            <Button
                                size="small"
                                style={{ minWidth: '80px' }}
                                onClick={() => setDoughnutSlot('cost')}
                                color={doughnutSlot === 'cost' ? 'secondary' : 'primary'}
                                variant={doughnutSlot === 'cost' ? 'outlined' : 'text'}
                            >
                                Cost
                            </Button>
                            <Button
                                size="small"
                                style={{ minWidth: '80px' }}
                                onClick={() => setDoughnutSlot('markup')}
                                color={doughnutSlot === 'markup' ? 'secondary' : 'primary'}
                                variant={doughnutSlot === 'markup' ? 'outlined' : 'text'}
                            >
                                Markup
                            </Button>
                            {/* <Button
                                size="small"
                                style={{ minWidth: '90px'}}
                                onClick={() => setDoughnutSlot('markup_perc')}
                                color={doughnutSlot === 'markup_perc' ? 'secondary' : 'primary'}
                                variant={doughnutSlot === 'markup_perc' ? 'outlined' : 'text'}
                            >
                                Markup %
                            </Button> */}
                            </Stack>
                        </Grid>
                    <MainCard contentSX={{ p: 2.25 }} sx={{
                        border: '2px',
                        bgcolor: 'rgba(250, 250, 250, 0.5)',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                        borderRadius: '5px' }}>
                        <Box sx={{ width: '100%', height: '300px' }}>
                            {contractSums && (
                            <Doughnut data={PieData} options={pieChartOptions}/>
                            )}
                        </Box>
                    </MainCard>
                

                {/* Doughnut Chart 2 with its title */}
                <Box sx={{ flexGrow: 1 }}>
                    <Typography style={{ fontWeight: 400,  fontSize: '1.8rem', fontFamily: 'Inter' }} sx={{ mt: 2 }}>
                        Chart Temporary Place Holder
                    </Typography>
                    <MainCard contentSX={{ p: 2.25 }} sx={{
                        border: '1px',
                        bgcolor: 'rgba(250, 250, 250, 0.5)',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                        borderRadius: '5px' }}>
                        <Box sx={{ width: '100%', height: '300px' }}> {/* Adjust chart size */}
                        <Doughnut data={PieData} options={pieChartOptions} />
                        </Box>
                    </MainCard>
                </Box>
            </Grid>
        {!!snackbar && (
            <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
            >
                <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
        )}
    </ThemeProvider>
    </Grid>
    </>
    );
}

export default TableComponent;