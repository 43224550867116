import React, { useState, useEffect, useCallback} from 'react';
import { DataGrid,  GridColumnMenu, gridFilteredSortedRowIdsSelector, gridVisibleColumnFieldsSelector, useGridApiContext, GridToolbarContainer, getGridNumericOperators, getGridDateOperators } from '@mui/x-data-grid';
import { Alert, Box, Button, Dialog, DialogTitle, DialogContent, 
        DialogContentText, DialogActions, Divider, Drawer, FormControl, Grid, IconButton, InputLabel, 
        List, ListItem, OutlinedInput, Paper, Snackbar, Select, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { red, blue, green, amber, grey } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import * as moment from 'moment';
import 'moment-timezone';
import CustomToolbar from '../Excel';
import CheckIcon from '@mui/icons-material/Check';
import PendingIcon from '@mui/icons-material/Pending';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FunctionsIcon from '@mui/icons-material/Functions';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useAuthStore from '../../auth/authStore';
import config from '../../config.json';
import ResultCounts from './BidResultCounts';
import fetchBidCounts from '../Bids/fetchBidCounts';
import fetchCategoryBidCounts from '../Bids/fetchCategoryBidCounts';
import CountUp from '../CountUp';
import MainCard from '../MainCard';
import { styled } from '@mui/material/styles';
import betweenFilterOperator from '../betweenFilter';
import betweenDateFilterOperator from '../betweenDateFilter';


const fieldNames = ['category', 'owner', 'project_title', 'estimator', 'bid_date', 'bid_month', 'owner_budget',
'pre_bid_date', 'jobwalk_date', 'bid_result_date', 'bid_result', 'bid_amount', 'bid_cost', 'notes']

const fieldNameMapping = {'category':"Category", 
'owner': "Owner", 
'project_title': "Project Title",  
'estimator': "Estimator", 
'bid_date': "Bid Date", 
'bid_month': "Bid Month", 
'owner_budget': "Owner Budget", 
'pre_bid_date': "Pre-bid Date",
'jobwalk_date': "Job Walk Date",
'bid_result_date': "Result Date", 
'bid_result': "Bid Result", 
'bid_amount': "Bid Amount", 
'bid_cost': "Bid Cost",
"markup": "Fixed Markup",
"markup_perc": "Markup %",
"gross_profit": "Gross Profit %",
'notes': "Notes"}

const fields = {id: null, category: '', owner: '',project_title: '',
estimator: '', bid_date: null, bid_month:'', owner_budget:'', 
pre_bid_date: null, jobwalk_date: null, bid_result:'', bid_amount:null, 
bid_cost:null, notes: ''}

const fieldLabels = [{name: 'category', label: 'Category' }, 
{name: 'owner', label: 'Owner' }, 
{name: 'project_title', label: 'Project Title' }, 
{name: 'estimator', label: 'Estimator' }, 
{name: 'bid_date', label: 'Bid Date' }, 
{name: 'bid_month', label: 'Bid Month' }, 
{name: 'owner_budget', label: 'Owner Budget' },
{name: 'pre_bid_date', label: 'Pre-bid Date' }, 
{name: 'jobwalk_date', label: 'Job Walk Date' },
{name: 'bid_result_date', label: 'Result Date' }, 
{name: 'bid_result', label: 'Bid Result' }, 
{name: "bid_amount", label: 'Bid Amount'},
{name: "bid_cost", label: 'Bid Cost'},
{name: 'notes', label: 'Notes' }
]

const dateFields = ["bid_date", "pre_bid_date", "jobwalk_date", "bid_result_date"];
const peopleFields = ["estimator"];
const statusFields = ["bid_result"];
const numberFields = ['owner_budget', 'bid_cost']
const booleanFields = [];
const categoryFields = ["category"];

const statusMapping = {
    'AWARDED':'Awarded',
    'NOT_AWARDED':'Not Awarded',
    'IN_PROGRESS':'In Progress',
    'PENDING':'Pending'
}



function commafy( num ) {
    var str = num.toString().split('.');
    if (str[0].length >= 5) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  }
  
function ccyFormat(num) {
    return num.toFixed(2);
  }

function RowAddTextField({ name, label, autoFocus, value, onChange }) {
    return (
      <TextField
        margin="dense"
        name={name}
        label={label}
        type="text"
        fullWidth
        value={value || ''}
        onChange={onChange}
      />
    );
  }

function BidsComponent() {
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState(fields);
    const [editedRows, setEditedRows] = useState({});
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [summaryDrawerRow, setSummaryDrawerRow] = useState(null);
    const [summaryDrawerOpen, setSummaryDrawerOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [peopleMapping, setPeopleMapping] = useState([]);
    const [peopleOptions, setPeopleOptions] = useState([]);
    const [snackbar, setSnackbar] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState([]);

    const initialBidCounts = Object.keys(statusMapping).reduce((acc, status) => {
        acc[status] = 0; // Initialize count as 0 for each status
        return acc;
      }, {});
    const [bidCounts, setBidCounts] = useState(initialBidCounts);
    const [CategoryBidCount, setCategoryBidCount] = useState([]);
    const [numprojects, setNumProjects] = useState(0);

    const { username, role } = useAuthStore();
    const editableRoles = ['admin', 'editor'];
    const isEditable = editableRoles.includes(role);

    useEffect(() => {
        const fetchData = async () => {
            try{
        const projectsResponse = await axios.get(`${config.apiBaseUrl}/api/bids/`, {withCredentials: true});
        const peopleResponse = await axios.get(`${config.apiBaseUrl}/api/people/`, {withCredentials: true});

        const projects = projectsResponse.data;
        const people = peopleResponse.data;

        const formattedProjects = projects.map((project) => {
            const formattedProject = { id: project.id };
    
            Object.keys(project).forEach((key) => {
                if (dateFields.includes(key)) {
                    // Check if the value is null or not a date before converting
                    formattedProject[key] = project[key] ? moment.tz(project[key], 'America/Los_Angeles').toISOString() : project[key];
                } else if (peopleFields.includes(key)) {
                    // If it's a peopleField, replace null with an empty string
                    formattedProject[key] = project[key] === null ? '' : project[key];
                } else {
                    // Just copy the value
                    formattedProject[key] = project[key];
                }
            });
            return formattedProject;
        });
        const formattedPeople = people.map((person) => ({
        id: person.id,
        ...person,
        }));

        const peopleMapping = {'':''};
        const peopleDropdownOptions = [{value: '', label: 'N/A'}];

        formattedPeople.forEach(person => {
        peopleMapping[person.id] = `${person.first_name} ${person.last_name}`;
        peopleDropdownOptions.push({value: person.id, label: `${person.first_name} ${person.last_name}`});
        });
        
        setNumProjects(formattedProjects.length);
        setRows(formattedProjects);
        setPeopleMapping(peopleMapping);
        setPeopleOptions(peopleDropdownOptions);
        setLoading(false);

        fetchBidCounts(config, setBidCounts)
        fetchCategoryBidCounts(config, setCategoryBidCount, CategoryBidCount);

        } catch (error) {
            console.error('Error fetching data: ', error);
    };
};
    
        fetchData();
      }, []);

    useEffect(() => {
    }, [editedRows]);

    const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    });
    
    const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    cellClassName: 'font-tabular-nums',
    };

    const percentage = {
        type: 'number',
        width: 130,
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }
            return `${params.value.toLocaleString()} %`
        },
        cellClassName: 'font-tabular-nums'
        };
    
    function dateformatter (params) {
        if (params.value === null || params.value === '') {
            return undefined;
        }
        return moment(params.value).isValid() ? moment(params.value).format('MM/DD/YYYY') : '';
    }

    function monthformatter (params) {
        if (params.value === null || params.value === '') {
            return undefined;
        }
        return moment(params.value).isValid() ? moment(params.value).format('MM/YYYY') : '';
    }

    const formatValue = (key, value) => {
        if (value === null || value === undefined) {
            return '-'
        }
        if (dateFields.includes(key)) {
            // Format the date to a readable string format
            return dateformatter(value);
        }
        else if (peopleFields.includes(key)) {
            return peopleMapping[value]
        }
        else if (numberFields.includes(key)) {
            return `$${commafy(ccyFormat(Number(value)))}`
        }
        // Handle other types of values if needed
        return value.toString();
    };

    const statusOptions = [
        { value: 'AWARDED', label: 'Awarded' },
        { value: 'NOT_AWARDED', label: 'Not Awarded' },
        { value: 'PENDING', label: 'Pending' },
        { value: 'IN_PROGRESS', label: 'In Progress' },
        { value: null, label: ''}
    ]

    const categoryOptions = [
        { value: 'PUBLIC', label: 'Public' },
        { value: 'PRIVATE', label: 'Private' },
        { value: 'JOC/IDIQ', label: 'JOC/IDIQ' },
        { value: null, label: ''}
    ]

    function getChipProps(params) {
        if (params.value === "AWARDED") {
          return {
            icon: <CheckIcon style={{ fill: green[500] }} />,
            label: "Awarded",
            style: {
                borderColor: green[500],
                color: green[500]
            }
            };
        } else if (params.value === "NOT_AWARDED")
        {
          return {
            icon: <CancelIcon style={{ fill: red[600] }} />,
            label: "Not Awarded",
            style: {
                borderColor: red[600],
                color: red[600]
            }
            };
        }
        else if (params.value === "IN_PROGRESS")
        {
            return {
                icon: <PendingIcon style={{ fill: amber[800] }} />,
                label: "In Progress",
                style: {
                  borderColor: amber[800],
                  color: amber[800]
                }
            };
        }
        else if (params.value === "PENDING")
        {
          return {
            icon: <AutorenewIcon style={{ fill: grey[500] }} />,
            label: "Pending",
            style: {
                borderColor: grey[500],
                color: grey[500]
            }
            };
        }
        }

    const columns = [
        { 
            headerName: "ID", 
            field: "id"
        },
        {
            field: "Summary",
            width: 80,
            renderCell: (params) => {
                return (
                  <IconButton onClick={(event) => {
                      handleOpenSummaryDrawer(params.row);
                    }}>
                      <SquareRoundedIcon
                    style={{marginLeft: '10px', maxWidth: '20px', maxHeight: '20px', minWidth: '20px', minHeight: '20px'}}
                    variant="contained"
                    color="primary"
                  />
                  </IconButton>
                );
              }
        },
        { 
            headerName: "Category", 
            field: "category",
            minWidth: 125,
            editable: isEditable,
            type: 'singleSelect',
            valueOptions: categoryOptions
        },
        { 
            headerName: "Owner", 
            field: "owner",
            minWidth: 165,
            editable: isEditable
        },
        { 
            headerName: "Project Title", 
            field: "project_title",
            minWidth: 400,
            flex: 1,
            editable: isEditable  
        },
        { 
            headerName: "Estimator", 
            field: "estimator",
            type: 'singleSelect',
            minWidth: 125,
            valueFormatter: function(params) {
                return peopleMapping[params.value]
            },
            valueOptions: peopleOptions,
            editable: isEditable
        },
        { 
            headerName: "Bid Date", 
            field: "bid_date",
            editable: isEditable,
            type: 'date',
            filterOperators: [betweenDateFilterOperator],
            minWidth: 125,
            valueFormatter: dateformatter
        },
        { 
            headerName: "Bid Month", 
            field: "bid_month",
            type: 'date',
            filterOperators: [betweenDateFilterOperator],
            minWidth: 125,
            valueGetter: (params) => {
                return params.row.bid_date
            },
            valueFormatter: monthformatter
        },
        { 
            headerName: "Owner Budget", 
            field: "owner_budget",
            type: 'number',
            filterOperators: [betweenFilterOperator],
            minWidth: 165,
            editable: isEditable,
            ...usdPrice
        },
        { 
            headerName: "Pre-bid Date", 
            field: "pre_bid_date",
            editable: isEditable,
            type: 'date',
            filterOperators: [betweenDateFilterOperator],
            minWidth: 125,
            valueFormatter: dateformatter
        },
        { 
            headerName: "Job Walk Date", 
            field: "jobwalk_date",
            editable: isEditable,
            type: 'date',
            filterOperators: [betweenDateFilterOperator],
            minWidth: 125,
            valueFormatter: dateformatter
        },
        { 
            headerName: "Result Date", 
            field: "bid_result_date",
            editable: isEditable,
            type: 'date',
            filterOperators: [betweenDateFilterOperator],
            minWidth: 125,
            valueFormatter: dateformatter
        },
        { 
            headerName: "Bid Result", 
            field: "bid_result",
            editable: isEditable,
            type: 'singleSelect',
            align:'left',
            minWidth: 140,
            valueOptions: statusOptions,
            renderCell: (params) => {
                if (!params.value)
                {return null}
                else {
                return <Chip size="small" variant="outlined" {...getChipProps(params)}/>;
                }
            }
        },
        { 
            headerName: "Bid Amount", 
            field: "bid_amount",
            type: 'number',
            filterOperators: [betweenFilterOperator],
            minWidth: 165,
            editable: isEditable,
            ...usdPrice
        },
        { 
            headerName: "Bid Cost", 
            field: "bid_cost",
            type: 'number',
            filterOperators: [betweenFilterOperator],
            minWidth: 125,
            editable: isEditable,
            ...usdPrice
            
        },
        { 
            headerName: "Markup", 
            field: "markup",
            minWidth: 165,
            valueGetter: getEstimatedMarkup,
            ...usdPrice
        },
        { 
            headerName: "Markup %", 
            field: "markup_perc",
            minWidth: 125,
            valueGetter: getEstimatedMarkupPercentage,
            ...percentage
        },
        { 
            headerName: "Gross Profit %", 
            field: "gross_profit",
            minWidth: 125,
            valueGetter: getGrossProfit,
            ...percentage
        },
        { 
            headerName: "Notes", 
            field: "notes",
            editable: isEditable,
            minWidth: 165
        },
        { 
            headerName: "Last Modified Time", 
            field: "last_modified_time",
            minWidth: 200,
            type: 'dateTime',
            valueFormatter: function (params) {
                if (params.value == null|| params.value === '') {
                    return null
                }
                else {
                    return moment(params.value).isValid() ? moment(params.value).format('MM/DD/YYYY HH:mm:ss') : '';
                }

            }
        },
        { 
            headerName: "Last Modified User", 
            field: "last_modified_user",
            minWidth: 125
        }

    ];

    // Form for New Row Confirmation
    const handleOpenNewRow = () => {
        setForm(fields);
        setOpen(true);
    };

    const handleCloseNewRow = () => {
        setOpen(false);
        setForm(fields);
    };

    // Dialogue for Delete Confirmation
    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true);
        };
      
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        };

    // Dialogue for Edit Confirmation
    const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
    };
  
    // Function to close the dialog
    const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    };

    // Dialogue for Delete Confirmation
    const handleOpenSummaryDrawer = (row) => {
        setSummaryDrawerRow(row);
        setSummaryDrawerOpen(true);
        };

    const handleCloseSnackbar = () => setSnackbar(null);

    const handleCSVFileLoaded = async (data) => {

        const reversedPeopleMapping = Object.fromEntries(
            Object.entries(peopleMapping).map(([key, value]) => [value, key])
          );

          const statusMapping = {'': null,
          'Awarded':'AWARDED',
          'Not Awarded':'NOT_AWARDED',
          'In Progress':'IN_PROGRESS',
          'Pending':'PENDING'
         }

        const acceptedHeaders = ["Category",
        "Owner",
        "Project Title",
        "Estimator",
        "Bid Date",
        "Bid Month",
        "Owner Budget",
        "Pre-bid Date", 
        "Job Walk Date",
        "Result Date",
        "Bid Result",
        "Bid Amount",
        "Bid Cost",
        "Notes"];

        const headerToFieldMap = Object.fromEntries(
            acceptedHeaders.map((header, index) => [header, fieldNames[index]])
        );

        const contractIdKey = headerToFieldMap["Contract ID"] || "Contract ID";

        const payload = data.map(row => {
            return Object.keys(row).reduce((newRow, key) => {
                if (acceptedHeaders.includes(key)) {
                    // Use the headerToFieldMap to find the new key for this column
                    const newKey = headerToFieldMap[key];
                    newRow[newKey] = row[key];
                  }
                  return newRow;
              }, {});
            }).filter(row => row[contractIdKey] && row[contractIdKey].trim() !== "");

        const convertDateFormat = (dateStr) => {
            // Check for blank or null values
            if (!dateStr) {
                return null;
            }
        
            const formattedDate = moment(dateStr);

            if (!formattedDate.isValid()) {
                throw new Error(`Invalid date format: ${dateStr}`);
            }
        
            return formattedDate.format('YYYY-MM-DD');
        };

        payload.forEach((row) => {
            statusFields.forEach(field=> {
                if (row[field] === "" || row[field] == null) {
                    row[field] = "";
                  }
                else {
                    row[field] = statusMapping[row[field]]
                }
            });
            peopleFields.forEach(field=> {
                if (row[field]) {
                    row[field] = reversedPeopleMapping[row[field]];
                  }
                });
            dateFields.forEach(field => {
                if (row[field] && row[field].trim() !== '') {
                    row[field] = convertDateFormat(row[field]);
                }
                else {
                    row[field] = null;
                }
            });
            numberFields.forEach(field => {
                if (row[field] !== "" && row[field] != null) {
                    // Enhanced to remove currency symbols and other non-numeric characters except minus sign and decimal
                    const cleanValue = row[field].toString().replace(/[^0-9.-]+/g, '');
                    const floatValue = parseFloat(cleanValue);
            
                    if (isNaN(floatValue)) {
                        throw new Error(`Invalid number format for key '${field}': ${row[field]}`);
                    }
            
                    row[field] = floatValue;
                } else {
                    row[field] = null;
                }
            });
        });

        try {
            const response = await axios.post(`${config.apiBaseUrl}/api/projects/upload_projects/`, JSON.stringify(payload), {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const highestId = Math.max(...rows.map((row) => row.id), 0);
            const newRows = response.data.new_rows.map((item, index) => ({
                id: highestId + index + 1,
                ...item,
            }));
    
            setRows((prev) => [
                ...prev,
                ...newRows
            ]);
    
            setSnackbar({ children: 'Successfully created new rows', severity: 'success' });
    
        } catch (error) {
            setSnackbar({ children: 'Submit went wrong', severity: 'error' });
            console.error('Error:', error);
        }
    };

    const handleFileLoaded = async (data) => {

        const reversedPeopleMapping = Object.fromEntries(
            Object.entries(peopleMapping).map(([key, value]) => [value, key])
          );

          const statusMapping = {'': null,
          'Awarded':'AWARDED',
          'Not Awarded':'NOT_AWARDED',
          'In Progress':'IN_PROGRESS',
          'Pending':'PENDING'
         }

        const acceptedHeaders = ["Category",
        "Owner",
        "Project Title",
        "Estimator",
        "Bid Date",
        "Bid Month",
        "Owner Budget",
        "Pre-bid Date", 
        "Job Walk Date",
        "Result Date",
        "Bid Result",
        "Bid Amount",
        "Bid Cost",
        "Notes"];

        const headerToFieldMap = Object.fromEntries(
            acceptedHeaders.map((header, index) => [header, fieldNames[index]])
          );

        if (!data[0]) {
            setSnackbar({ children: 'Invalid file format: Missing headers', severity: 'error' });
            return;
        }

        // clean end of line \r values
        let cleanedHeaders = data[0].map(header => header.replace(/\r?\n|\r|\$/g, ''));

        const isHeaderValid = acceptedHeaders.every(header => cleanedHeaders.includes(header));
        if (!isHeaderValid) {
            setSnackbar({ children: 'Invalid file format: Missing required headers', severity: 'error' });
            return;
        }

        let cleanedData = data
            .filter(row => !row.every(field => field === "" || field == null)) // Skip rows where all fields are empty or null
            .map(row => 
                row.map(field => typeof field === 'string' ? field.replace(/\r?\n|\r|\$/g, '') : field ?? null)
            );

       // Create a list of valid header indices
        const validIndices = cleanedHeaders.map((header, index) => acceptedHeaders.includes(header) ? index : null).filter(index => index !== null);

        const validFieldNames = cleanedHeaders.map(header => headerToFieldMap[header]).filter(Boolean);


        // Use validIndices to filter both headers and data
        cleanedHeaders = cleanedHeaders.filter((_, index) => validIndices.includes(index));

        cleanedData = cleanedData.map(row => row.filter((_, index) => validIndices.includes(index)));

        // Check if any valid headers are left
        if (cleanedHeaders.length === 0) {
            setSnackbar({ children: 'Invalid file format: No matching columns found', severity: 'error' });
            return;
        }


        // Exclude the header row and zip rows into dictionaries
        const payload = cleanedData.slice(1).map(row => {
            return Object.fromEntries(row.map((cell, index) => [validFieldNames[index], cell]));
        });

        const convertDateFormat = (dateStr) => {
            // Check for blank or null values
            if (!dateStr) {
                return null;
            }
        
            const formattedDate = moment(dateStr);

            if (!formattedDate.isValid()) {
                throw new Error(`Invalid date format: ${dateStr}`);
            }
        
            return formattedDate.format('YYYY-MM-DD');
        };

        payload.forEach((row) => {
            statusFields.forEach(field=> {
                if (row[field] === "" || row[field] == null) {
                    row[field] = "";
                  }
                else {
                    row[field] = statusMapping[row[field]]
                }
            });
            peopleFields.forEach(field=> {
                if (row[field]) {
                    row[field] = reversedPeopleMapping[row[field]];
                  }
                });
            dateFields.forEach(field => {
                if (row[field] && row[field].trim() !== '') {
                    row[field] = convertDateFormat(row[field]);
                }
                else {
                    row[field] = null;
                }
            });
            numberFields.forEach(field => {
                if (row[field] !== "" && row[field] != null) {
                    const floatValue = parseFloat(row[field].toString().replace(/,/g, ''));
        
                    if (isNaN(floatValue)) {
                        throw new Error(`Invalid number format for key '${field}': ${row[field]}`);
                    }
        
                    row[field] = floatValue;
                } else {
                    row[field] = null;
                }
            });
        });

        try {
            const response = await axios.post(`${config.apiBaseUrl}/api/bids/upload_bids/`, JSON.stringify(payload), {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const highestId = Math.max(...rows.map((row) => row.id), 0);
            const newRows = response.data.new_rows.map((item, index) => ({
                id: highestId + index + 1,
                ...item,
            }));
    
            setRows((prev) => [
                ...prev,
                ...newRows
            ]);
    
            setSnackbar({ children: 'Successfully created new rows', severity: 'success' });
    
        } catch (error) {
            setSnackbar({ children: 'Submit went wrong', severity: 'error' });
            console.error('Error:', error);
        }
    };

    function findDifference(dict1, dict2) {
        const difference = {};
      
        // Iterate over keys of dict1
        for (const key in dict1) {
          // Check if the values are not equal
          if (dict1[key] !== dict2[key]) {
            difference[key] = dict1[key];
          }
        }
      
        return difference;
      }
    
      function updateDictionary(dict, difference) {
        const updatedDict = { ...dict };

        for (const key in difference) {
            if (difference.hasOwnProperty(key)) {
          updatedDict[key] = difference[key];
        }
      }
      return updatedDict
    }

    function getEstimatedAmount(params) {
        return params.row.bid_amount;
      }


    function getEstimatedMarkup(params) {
        return getEstimatedAmount(params) - params.row.bid_cost
        }
        
    function getEstimatedMarkupPercentage(params) {
        const estimatedMarkup = getEstimatedMarkup(params);
        return ((estimatedMarkup / params.row.bid_cost) * 100).toFixed(2);
    }
    
    function getGrossProfit(params) {
        const estimatedAmount = getEstimatedAmount(params);
        const estimatedMarkup = getEstimatedMarkup(params);
        return ((estimatedMarkup / estimatedAmount) * 100).toFixed(2);
    }

    const handleEditCellChange = useCallback(
        (params, params2) => {
            if (typeof params !== 'object' || params === null || !params.hasOwnProperty('id')) {
                console.error("Invalid params passed to handleEditCellChange");
                return;
            }

            const difference = findDifference(params, params2)
            const updatedRows = editedRows ? { ...editedRows} : {};
            const id = params.id
            updatedRows[id] = updatedRows[id] || {};
            updatedRows[id]["id"] = id
            for (let field in difference) {
                if (field === 'id') continue;

                // Handle date field formatting
                if (dateFields.includes(field)) {
                    if (difference[field] === "Invalid date") {
                        updatedRows[id][field] = null;
                    }
                    else {
                        let date = new Date(difference[field]);
                        let isoFormattedDateString = date.toISOString().split('T')[0];
                        updatedRows[id][field] = isoFormattedDateString;
                    }
                }
                else {
                    updatedRows[id][field] = difference[field];
                }
            }
            setEditedRows(updatedRows)

            return updatedRows[params.id];
    },
        [editedRows]
      );

    const handleProcessRowUpdateError = React.useCallback((error) => {
            setSnackbar({ children: 'row update went wrong', severity: 'error' });
          }, []);

    const handleInputChange = (event, newValue = null, fieldName = null) => {
        if (event && event.target && event.target.name) {
            setForm({
            ...form,
            [event.target.name]: event.target.value || "",
            });
        } else if (newValue && fieldName) {
            setForm({
            ...form,
            [fieldName]: newValue || "",
            });
        }
    };

    const handleSubmit = () => {
        form['last_modified_time'] = moment();
        form['last_modified_user'] = username;
        axios.post(`${config.apiBaseUrl}/api/bids/`, form, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }            
        })
        .then((response) => {
        setSnackbar({ children: 'Successfully created new row', severity: 'success' });
        const highestId = Math.max(...rows.map((row) => row.id), 0);
        setRows((prev) => [
            ...prev,
            {
            id: highestId + 1,
            ...response.data,
            },
        ]);
        })
        .catch((error) => {
        setSnackbar({ children: 'Submit went wrong', severity: 'error' });
        console.error('Error:', error);
        });

    handleCloseNewRow();
    };
    

    const handleConfirmEdit = () => {
        selectedRows.forEach((id) => {
            const rowChanges = editedRows[id];
            const originalRow = rows.find(dict => dict.id === id);
            const url = `${config.apiBaseUrl}/api/bids/${id}/`;
             // Ensure we found a matching row
            if (!rowChanges) {
                console.error(`Could not find row with id ${id} in current state.`);
                return;
            }

            const updatedRow = updateDictionary(originalRow, rowChanges)
            for (let field in updatedRow) {
                if (field === 'id') continue;

                // Handle date field formatting
                if (dateFields.includes(field)) {
                    if (moment(updatedRow[field]).isValid()) {
                        updatedRow[field] = moment(updatedRow[field]).format('YYYY-MM-DD');
                    } else {
                        updatedRow[field] = null;
                    }
                }
            }

            updatedRow['last_modified_time'] = moment();
            updatedRow['last_modified_user'] = username;
            axios.put(url, updatedRow, {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                }
              })
                .then((response) => {
                    if (response.status === 200) {
                        setSnackbar({ children: 'Successfully saved changes', severity: 'success' });
                        setRows((prev) =>
                        prev.map((row) => {
                            if (row.id !== id) {
                            return row;
                            }

                            return { ...row, ...response.data };
                        })
                        );
                        fetchBidCounts(config, setBidCounts);
                        fetchCategoryBidCounts(config, setCategoryBidCount, CategoryBidCount);
                    }
                else {
                    // Handle any other statuses or errors
                    throw new Error('Failed to save edits.');
                  }                
            })
            .catch((error) => {
                setSnackbar({ children: 'Confirm edit went wrong', severity: 'error' });
                console.error('Error:', error);
            });
        handleCloseEditDialog();
    });
    }
    
    const handleDelete = () => {
        axios.post(`${config.apiBaseUrl}/api/bids/bulk-delete/`, { ids: selectedRows }, { withCredentials: true })
          .then(() => {
            setRows((prev) => prev.filter((row) => !selectedRows.includes(row.id)));
            setSnackbar({ children: 'Successfully deleted row(s)', severity: 'success' });
          })
          .catch((error) => {
            setSnackbar({ children: 'Delete went wrong', severity: 'error' });
            console.error('Error:', error);
          });
      
        handleCloseDeleteDialog();
      };

    
    function AggregationMenuItem(props) {
        const { aggregationHandler, columnName } = props;
        return (
            <MenuItem onClick={() => aggregationHandler(columnName)}>
            <ListItemIcon>
                <FunctionsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{`Toggle Aggregation`}</ListItemText>
            </MenuItem>
        );
    }

    function CustomColumnMenu(props) {
        return (
          <GridColumnMenu
            {...props}
            slots={{
                columnMenuUserItem: AggregationMenuItem
              }}
              slotProps={{
                columnMenuUserItem: {
                  displayOrder: 15,
                  columnName: props.colDef.field,
                  aggregationHandler: handleToggleAggregation
                },
              }}
            />
          );
        }

      const handleToggleAggregation = (field) => {
        setSelectedColumns((prevSelectedColumns) =>
          prevSelectedColumns.includes(field)
            ? prevSelectedColumns.filter((col) => col !== field)
            : [...prevSelectedColumns, field]
        );
      };

    //   function escapeRegExp(value) {
    //     return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    //   }
      
    
    // const requestSearch = (searchValue) => {
    //     setSearchText(searchValue);
    //     const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    //     const filteredRows = data.rows.filter((row) =>
    //       Object.keys(row).some((field) => searchRegex.test(row[field].toString()))
    //     );
    //     setRows(filteredRows);
    // };
      

    const calculateSum = (rows, field) => {
        return rows.reduce((acc, row) => acc + parseFloat(row[field] || 0), 0);
    };
    
    const AggregatorToolbar = () => {
        const apiRef = useGridApiContext();
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const visibleColumns = gridVisibleColumnFieldsSelector(apiRef);

        const filteredRows = filteredSortedRowIds.map((id) => {
            const row = apiRef.current.getRow(id);
            return Object.keys(row)
              .filter((key) => visibleColumns.includes(key))
              .reduce((obj, key) => {
                obj[key] = row[key];
                return obj;
              }, {});
          });

        const sums = selectedColumns.reduce((acc, column) => {
            acc[column] = calculateSum(filteredRows, column);
            return acc;
          }, {}); // Using the calculateSum function
    
    return (
        <GridToolbarContainer style={{ backgroundColor: '#F1F1F1'}}>
      <Box display="flex" justifyContent="space-between" width="100%">
        {/* First toolbar section (e.g., your existing toolbar customizations) */}
        <div>
        <CustomToolbar onCSVFileLoaded={handleCSVFileLoaded} onFileLoaded={handleFileLoaded} foreignkeyfields={peopleFields} foreignkeymapping={peopleMapping} />
        </div>
        </Box>
        {/* Second row with the aggregations */}
        <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
            {visibleColumns.map((column) => (
            selectedColumns.includes(column) && ( // Move this condition outside the Box
            <Box key={column} flexBasis={0} flexGrow={1} maxWidth="100%" marginRight={1} marginBottom={1}>
                <MainCard contentSX={{ p: 2.25 }} sx={{
                    width:'auto',
                    height:'auto',
                    border: '2px',
                    bgcolor: 'rgba(250, 250, 250, 0.3)',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '4px' }}>
                    <Typography variant="h7" fontFamily='"Inter"'>
                        {`Sum of ${fieldNameMapping[column]}:`}
                    </Typography>
                    <Typography variant="h6" fontFamily='"Inter"'>
                        {`$${commafy(ccyFormat(sums[column]))}`}
                    </Typography>
                </MainCard>
            </Box>
            )
            ))}
        </Box>
    </GridToolbarContainer>
  );
};

// STYLING
    const theme = createTheme({
        palette: {
          neutral: {
            main: '#64748B',
            contrastText: '#fff',
          },
          secondary: {
            light: '#C5E3EC',
            main: '#1AA7EC',
            dark: '#AADDEC',
            contrastText: '#000',
          },
        },
        typography: {
            fontFamily: [
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
              'Poppins',
              'Inter'
            ].join(','),
          },
      });

      const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
          fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
          fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
          fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
          fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
          fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
          fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
      }));

      function CustomNoRowsOverlay() {
        return (
          <StyledGridOverlay>
            <svg
              width="120"
              height="100"
              viewBox="0 0 184 152"
              aria-hidden
              focusable="false"
            >
              <g fill="none" fillRule="evenodd">
                <g transform="translate(24 31.67)">
                  <ellipse
                    className="ant-empty-img-5"
                    cx="67.797"
                    cy="106.89"
                    rx="67.797"
                    ry="12.668"
                  />
                  <path
                    className="ant-empty-img-1"
                    d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                  />
                  <path
                    className="ant-empty-img-2"
                    d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                  />
                  <path
                    className="ant-empty-img-3"
                    d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                  />
                </g>
                <path
                  className="ant-empty-img-3"
                  d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                />
                <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                  <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                  <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                </g>
              </g>
            </svg>
            <Box sx={{ mt: 1 }}>No Rows</Box>
          </StyledGridOverlay>
        );
      }


    if (loading) return (<ThemeProvider theme={theme}>
        <Box sx={{display: 'flex'}}
        minHeight="50vh" alignItems="center"
        justifyContent="center">
        <CircularProgress sx = {{color:'secondary'}}/>
        </Box>
        </ThemeProvider>
        );     

    return (
        <>

        {/* Interactive Buttons */}
            <div style={{ margin: '0px' }}>
            <ThemeProvider theme={theme}>
                <div style={{ marginTop: '1vh' }}>
                <Grid container rowSpacing={4.5} columnSpacing={2.75} padding={1.5}
                    style={{ margin: 0, width: '93%', boxSizing: 'border-box' }}>
                    {/* row 1 */}
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography variant="h4"  style={{ fontFamily: 'Inter' }}>Total Bids:&nbsp;</Typography>
                        </Grid>
                        <Grid item>
                            <CountUp  style={{ fontFamily: 'Poppins' }} fetchedValue={numprojects} duration={1500} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0.5} justifyContent="space-evenly" style={{ marginTop: '20px' }}>
                         {Object.keys(statusMapping).map((status) => (
                            <Grid item xs={12} sm={6} md={4} lg={2} key={status}>
                                <ResultCounts
                                    status={status} 
                                    bidCount={bidCounts[status]} 
                                    categoryCount={CategoryBidCount[status]}
                                    statusMapping={statusMapping}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />
                <div style={{
                        display: 'flex', // Enables flexbox
                        justifyContent: 'flex-end', // Aligns children (the buttons) to the right
                        width: 'calc(95%)',
                        height: '10%', // Full height of the container (adjust as needed)
                        padding: '5px', // Adds some space inside the container's edges
                        }}>            
                    <Button
                        sx={{ mx: 0.3 }}
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={handleOpenNewRow}
                        >
                        Add
                    </Button>
                    <Button 
                        sx={{ mx: 0.3 }}
                        variant ="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={handleOpenDeleteDialog}>
                            Delete
                    </Button>
                    <Button 
                        sx={{ mx: 0.3 }}
                        variant ="contained"
                        color="secondary"
                        startIcon={<EditIcon />}
                        onClick={handleOpenEditDialog}>
                            Save Edits
                    </Button>
                </div>

        {/* Add New Row Form */}
            <Dialog open={open} onClose={handleCloseNewRow}
            fullWidth={true} // this sets it to take full width of the screen
            maxWidth="md" // adjust to 'sm', 'md', 'lg', 'xl' as per your need
            >
                <DialogTitle>Add new row</DialogTitle>
                <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={3}>
                    {fieldLabels.map((field, index) => {

                    const isStatusField = statusFields.includes(field.name);
                    const isPeopleField = peopleFields.includes(field.name);
                    const isDateField = dateFields.includes(field.name);
                    const isBooleanField = booleanFields.includes(field.name);
                    const isCategoryField = categoryFields.includes(field.name);

                    return (
                        <Grid item xs={6} key={field.name} > {/* xs={6} means it takes up half of the grid */}
                        {(isStatusField || isPeopleField || isCategoryField ) ? (
                        <Box mt={1} style={{ height: '56px', boxSizing: 'border-box' , marginTop: '16px' }}>
                        <FormControl key={field.name} variant="outlined" fullWidth >
                            <InputLabel htmlFor={field.name}>{field.label}</InputLabel>
                            <Select
                                name={field.name}
                                value={form[field.name]}
                                onChange={(event) => {
                                    handleInputChange(event);
                                  }}
                                input={
                                    <OutlinedInput label={field.label} name={field.name} id={field.name} />
                                    }
                            >
                                {(isStatusField ? statusOptions: isCategoryField? categoryOptions : isPeopleField ? peopleOptions : []).map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        </Box>
                        
                    ) : isDateField ? (
                        <Box mt={1} style={{ height: '56px', boxSizing: 'border-box' , marginTop: '16px' }}>
                        <DatePicker
                          label={field.label}
                          value={form[field.name]}
                          onChange={(newValue) => {
                            handleInputChange(null, moment(newValue.$d).format('YYYY-MM-DD'), field.name);
                          }}
                          slotProps={{ textField: { variant: 'outlined', fullWidth: true } }}
                        />
                        </Box>
                      ) : isBooleanField ? (
                        <Box mt={1} style={{ height: '56px', boxSizing: 'border-box' , marginTop: '16px' }}>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel htmlFor={field.name}>{field.label}</InputLabel>
                                <Select
                                    name={field.name}
                                    value={form[field.name]}
                                    onChange={handleInputChange}
                                    input={<OutlinedInput label={field.label} name={field.name} id={field.name} />}
                                >
                                    <MenuItem value={true}>Completed</MenuItem>
                                    <MenuItem value={false}>Uncompleted</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    ) : (
                        <Box mt={1} style={{ height: '56px', boxSizing: 'border-box' }}>
                    <RowAddTextField
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        value={form[field.name]}
                        onChange={handleInputChange}
                    />
                    </Box>
                        )}                        
                    </Grid>
                    );
                    }
                    )}
                </Grid>
                </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewRow}>Cancel</Button>
                    <Button onClick={handleSubmit}>Add</Button>
                </DialogActions>
            </Dialog>   
            
            {/* Edit Confirmation Box  */}
            <Dialog
                open={editDialogOpen}
                onClose={handleCloseEditDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Commit Changes?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Do you confirm to edit these rows?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                    No
                    </Button>
                    <Button onClick={handleConfirmEdit} color="primary" autoFocus>
                    Yes
                    </Button>
                </DialogActions>
                </Dialog>

            
            {/* Delete Confirmation Box  */}

            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Delete Rows?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Do you confirm to delete these rows?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                    No
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                    Yes
                    </Button>
                </DialogActions>
                </Dialog>

                {/* Data Grid Parameters */}
                <Box sx={{ height: 'auto', overflow: 'hidden', margin: 'auto', width: '95vw', padding:'2'}}>
                    <DataGrid
                        density="compact"
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        slots={{ columnMenu: (props) => <CustomColumnMenu {...props} toggleAggregation={handleToggleAggregation} />,
                                toolbar: AggregatorToolbar,
                                noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                              },
                        }}
                        processRowUpdate={(updatedRow, originalRow) =>
                            handleEditCellChange(updatedRow, originalRow)
                        }
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setSelectedRows(newRowSelectionModel);
                        }}                        
                        initialState={{
                            ...rows.initialState,
                            columns: {
                                ...rows.initialState?.columns,
                                columnVisibilityModel: {
                                id: false
                                },
                            },
                            }}
                            sx={{
                                width: 'auto',
                                margin: 'auto',
                                fontWeight: 400,
                                m:2,
                                fontSize: 14,
                                boxShadow: 3,
                                borderRadius: 0,
                                border: '2px solid #E7E7E7',
                                fontFamily: [
                                    '-apple-system',
                                    'BlinkMacSystemFont',
                                    '"Segoe UI"',
                                    'Roboto',
                                    '"Helvetica Neue"',
                                    'Arial',
                                    'sans-serif',
                                    '"Apple Color Emoji"',
                                    '"Segoe UI Emoji"',
                                    '"Segoe UI Symbol"',
                                  ].join(','),
                                  WebkitFontSmoothing: 'auto',
                                  letterSpacing: 'normal',
                                '& .MuiDataGrid-main': {  // This targets the main grid area excluding the toolbar
                                    border: '2px solid rgba(0, 0, 0, 0.2)', // Darker border for the data grid itself
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                                    borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                                    // add more css for customization
                                },
                                '.MuiDataGrid-row:last-child .MuiDataGrid-cell': {
                                    borderBottom: 'none',
                                },
                                '.MuiDataGrid-colCell': {
                                borderRight: '1px solid #ccc',
                                },
                                '.MuiDataGrid-colCell:last-child': {
                                borderRight: 'none',
                                },
                                '& .MuiDataGrid-row:nth-of-type(odd)': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)', // Slight grey for odd rows
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                  border: 0,
                                  borderTop: 0,
                                  borderBottom: 1,
                                  borderRadius: 0,
                                  backgroundColor: "rgba(0, 0, 0, 0.2)"
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                                    width: '0.4em',
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                                    background: '#f1f1f1',
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#888',
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                                    background: '#555',
                                },
                            }}
                    />
                </Box>
                <Drawer
                    anchor="right"
                    open={summaryDrawerOpen}
                    onClose={() => setSummaryDrawerOpen(false)}
                >
                <div style={{ padding: 20, minWidth: 300 }}>
                    <IconButton onClick={() => setSummaryDrawerOpen(false)} style={{ marginLeft: 'auto' }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" gutterBottom>Summary</Typography>
                    <List>
                    {summaryDrawerRow && Object.entries(summaryDrawerRow).map(([key, value]) => {
                        if (!fieldNameMapping[key]) {
                            // Skip this iteration if there's no mapping for the key
                            return null;
                        }
                        return (
                        <React.Fragment key={key}>
                            <ListItem>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{`${fieldNameMapping[key] ? fieldNameMapping[key]: ''}:`}</Typography>
                                <Typography variant="subtitle2" style={{ marginLeft: 8 }}> {formatValue(key, value)}</Typography>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </React.Fragment>
                        );
                    })}
                    </List>
                </div>
                </Drawer>
                </Grid>
                </div>                
                {!!snackbar && (
                    <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={100000}
                    >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </ThemeProvider>
                
            </div>
            
            
    </>
    );
}

export default BidsComponent;