const fetchCategoryBidCounts = (config, setCategoryBids, currentCategoryBids) => {
    fetch(`${config.apiBaseUrl}/api/bids/category-count/`, { credentials: 'include' }) // changed to 'include' to send cookies in a cross-origin resource sharing (CORS) request
      .then(response => response.json())
      .then(data => {
        const updatedCategoryBids = { ...currentCategoryBids };

        data.forEach(item => {
          const status = item.bid_result;
          const categoryname = item.annotated_category;

          if (!updatedCategoryBids[status]) {
            updatedCategoryBids[status] = {};
          }
          updatedCategoryBids[status][categoryname] = item.num_bids  // Replace the existing number with the new one
      });
        setCategoryBids(updatedCategoryBids);
      })
      .catch(error => {
        console.error('Error fetching CategoryBids data:', error);
      });
  };
  
  export default fetchCategoryBidCounts;
  