import { GridToolbarDensitySelector, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarContainer,
    GridToolbarExportContainer, useGridApiContext, GridCsvExportMenuItem,   gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material'
import React, { useRef } from "react";
import Papa from 'papaparse';

function ExcelExportMenuItem(props) {
    const apiRef = useGridApiContext();
    const { hideMenu, filename, foreignkeyMapping, foreignkeyFields } = props;

    return (
      <MenuItem
        onClick={() => {
            const statusMapping = {'': null,
            'In Progress':'IN_PROGRESS',
            'Submitted':'SUBMITTED',
            'On Hold':'ON_HOLD',
            'NTP':'NTP',
            'Cancelled':'CANCELLED' }

            const budgetMapping = {'': null,
            'Not Ready':'NOT_READY',
            'Ready':'READY' }

            const resultMapping = {'': null,
            'Yes':'YES',
            'No':'NO',
            'Pending':'PENDING'
          }


            const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
            const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
  
            
            const data = filteredSortedRowIds.map((id) => {
              const row = {};
              
              visibleColumnsField.forEach((field) => {
                const cellParams = apiRef.current.getCellParams(id, field);

                // Check if the field requires mapping
                if (foreignkeyFields?.includes(field) && foreignkeyMapping?.[cellParams.value]) {
                  row[cellParams.colDef.headerName] = foreignkeyMapping[cellParams.value];
                } else if (cellParams.colDef.headerName.includes('Proposal Status')) {
                  row[cellParams.colDef.headerName] = statusMapping[cellParams.value] || cellParams.value;
                } else if (cellParams.colDef.headerName.includes('Budget Status')) {
                  row[cellParams.colDef.headerName] = budgetMapping[cellParams.value] || cellParams.value;
                }
                  else if (cellParams.colDef.headerName.includes('Result')) {
                  row[cellParams.colDef.headerName] = statusMapping[cellParams.value] || cellParams.value;
                }
                  else {
                  row[cellParams.colDef.headerName] = cellParams.value;
                }
                });
              return row;
            });

          const worksheet = XLSX.utils.json_to_sheet(data);
          const workbook = XLSX.utils.book_new();

          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
          const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

          saveAs(blob, `${filename}.xlsx`);
          hideMenu?.();
        }}
      >
        Download as Excel
      </MenuItem>
    );
}

const csvOptions = { fileName:"MIK_Project_Data", delimiter: ',' };

function CustomExportButton(props) {
    return (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions}/>
            <ExcelExportMenuItem filename="MIK_Project_Data" foreignkeyFields={props.foreignkeyFields} foreignkeyMapping={props.foreignkeyMapping}/>
        </GridToolbarExportContainer>
    );
}


export function ExcelImportButton(props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#0000FF', // Replace with your desired blue color code
      },
      secondary: {
        main: '#FF0000', // Replace with your desired secondary color code
      },
    },
  });

    const { onCSVFileLoaded, onFileLoaded } = props;
    const fileInput = useRef(null);
  
    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
    
      reader.onload = (evt) => {
        const bstr = evt.target.result;
    
        if (file.name.endsWith('.csv')) {
          Papa.parse(file, {
            complete: function(results) {          
              onCSVFileLoaded(results.data);
            },
            header: true,
            skipEmptyLines: true, // Skips empty lines in the input
            dynamicTyping: true, // Automatically converts numeric and boolean values in strings to their respective types
          });
        } else {
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: "YYYY-MM-DD", defval:"" });
          onFileLoaded(data);
        }
      };
    
      if (file.name.endsWith('.csv')) {
        reader.readAsText(file);
      } else {
        reader.readAsBinaryString(file);
      }
    };
  
    const handleImportClick = () => {
      fileInput.current.click();
    };
  
    return (
      <ThemeProvider theme={theme}>
      <div>
        <input
          type="file"
          hidden
          ref={fileInput}
          onChange={handleFileUpload}
          accept=".xlsx,.xls,.csv"
        />
        <Button variant="default" sx = {{color:'#1976d2', padding: '4px 5px', fontSize: '0.8125rem'}} startIcon={<FileUploadOutlinedIcon />} onClick={handleImportClick}>
          Import
        </Button>
      </div>
      </ThemeProvider>
    );
  }


function CustomToolbar({ onCSVFileLoaded, onFileLoaded, foreignkeyfields, foreignkeymapping }) {
  return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton foreignkeyfields={foreignkeyfields} foreignkeymapping={foreignkeymapping}/>
            <ExcelImportButton onCSVFileLoaded={onCSVFileLoaded} onFileLoaded={onFileLoaded} />
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}
export default CustomToolbar