const fetchProjectCounts = (config, setProjectCounts) => {
    fetch(`${config.apiBaseUrl}/api/projects/status-count/`, { credentials: 'include' }) // changed to 'include' to send cookies in a cross-origin resource sharing (CORS) request
      .then(response => response.json())
      .then(data => {
        let updatedStatus = {};

        // Iterate over each status in the data and update the count
        data.forEach(dataStatus => {
          updatedStatus[dataStatus.proposal_status] = dataStatus.num_projects;
        });
        
        setProjectCounts(prevProjectCounts => ({
          ...prevProjectCounts, 
          ...updatedStatus,
        }));
      })      
      .catch(error => {
        console.error('Error fetching ProjectCounts data:', error);
      });
  };
  
  export default fetchProjectCounts;
  