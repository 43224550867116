import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {Box, Paper, TableContainer, Typography} from '@mui/material'

function commafy( num ) {
  var str = num.toString().split('.');
  if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
}

function ccyFormat(num) {
  return num.toFixed(2);
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  });
  
  const usdPrice = {
  type: 'number',
  width: 130,
  valueFormatter: ({ value }) => currencyFormatter.format(value),
  cellClassName: 'font-tabular-nums',
  };

  const percentage = {
    type: 'number',
    width: 130,
    valueFormatter: (params) => {
        if (params.value == null) {
            return '';
        }
        return `${ccyFormat(params.value)} %`
    },
    cellClassName: 'font-tabular-nums'
    };

function subtotal_sum(data, fieldName) {
  if (!data || typeof data !== 'object') {
    return 0; // Return a default value if data is not valid
  }

  return Object.keys(data).reduce((sum, key) => {
    const item = data[key];
    return sum + (item[fieldName] || 0);
  }, 0);
}

function subtotal_average(data = {}) {
  const result = Object.keys(data).reduce((acc, key) => {
          acc.markup_sum += data[key]['markup'];
          acc.ntp_sum += data[key]['total_ntp_amount']
          acc.count += 1;
      return acc;
  }, { markup_sum: 0, ntp_sum: 0 });

  return result.count > 0 ? result.markup_sum / result.ntp_sum : 0;
}

const columns = [
  { 
    headerName: "Contract ID", 
    field: "contract_id",
    minWidth: 200,
    flex: 1,
  },
  { 
    headerName: "Revenue", 
    field: "total_ntp_amount",
    type: 'number',
    minWidth: 125,
    flex: 1,
    ...usdPrice
},
{ 
    headerName: "Cost", 
    field: "total_estimated_cost",
    type: 'number',
    minWidth: 125,
    flex: 1,
    ...usdPrice
},
{ 
  headerName: "Markup", 
  field: "markup",
  type: 'number',
  minWidth: 125,
  flex: 1,
  ...usdPrice
},
{ 
  headerName: "Markup %", 
  field: "markup_percentage",
  type: 'number',
  minWidth: 75,
  flex: 1,
  ...percentage
}
]

export function TotalFooterComponents(subtotals) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1, borderTop: 5, borderColor: '#efefef' }}>
        <Typography variant="h6" fontFamily='"Inter"'>Total</Typography>
        <Typography variant="subtitle1" fontFamily='"Inter"'>${commafy(ccyFormat(subtotal_sum(subtotals['subtotals'], "total_ntp_amount")))}</Typography>
        <Typography variant="subtitle1" fontFamily='"Inter"'>${commafy(ccyFormat(subtotal_sum(subtotals['subtotals'], "total_estimated_cost")))}</Typography>
        <Typography variant="subtitle1" fontFamily='"Inter"'>${commafy(ccyFormat(subtotal_sum(subtotals['subtotals'], "markup")))}</Typography>
        <Typography variant="subtitle1" fontFamily='"Inter"'>{ccyFormat(subtotal_sum(subtotals['subtotals'], "markup")/subtotal_sum(subtotals['subtotals'], "total_ntp_amount")*100)}%</Typography>
      </Box>
    </Box>
  );
}

export default function AggregationTable({subtotals}) {
  const rows = subtotals ? Object.values(subtotals) : [];
  return (
    
    <Box sx={{ height: '85vh', width: 'auto', padding: 2 }}>
      <DataGrid
      rows={rows}
      columns={columns}
      getRowId={(row) => row.contract_id}
      slots={{
        footer: TotalFooterComponents,
      }}
      slotProps={{
        footer: { subtotals }
      }}
      sx={{
        fontWeight: 400,
        fontSize: 17,
        boxShadow: 3,
        borderRadius: 0,
        border: '2px solid #E7E7E7',
        "& .MuiDataGrid-footer": {
          border: 0,
          borderTop: 0,
          borderBottom: 0,
          borderRadius: 0.1,
          backgroundColor: "rgba(0, 0, 0, 0.1)"
        },
        "& .MuiDataGrid-columnHeaders": {
          border: 0,
          borderTop: 0,
          borderBottom: 0,
          borderRadius: 0.1,
          backgroundColor: "rgba(0, 0, 0, 0.1)"
        },
        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            width: '0.4em',
            borderRadius: '2px'
        },
        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
        },
        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    }}
      >
    </DataGrid>
  
    {/* //   <Table sx={{ minWidth: 700 }} aria-label="spanning table">
    //   <TableHead>
    //     <TableRow>
    //       <TableCell align="center" colSpan={4}>
    //         Contract Subtotals
    //       </TableCell>
    //       <TableCell align="right">Price</TableCell>
    //     </TableRow>
    //     <TableRow>
    //       <TableCell>Contract ID</TableCell>
    //       <TableCell align="right">NTP Amount</TableCell>
    //       <TableCell align="right">Cost</TableCell>
    //       <TableCell align="right">Fixed Markup</TableCell>
    //       <TableCell align="right">Markup %</TableCell>
    //     </TableRow>
    //   </TableHead>
    //   <TableBody>
    //     {Object.values(subtotals).map((contract, index) => (
    //       <TableRow key={index}>
    //         <TableCell>{contract.contract_id}</TableCell>
    //         <TableCell align="right">${commafy(ccyFormat(contract.total_ntp_amount))}</TableCell>
    //         <TableCell align="right">${commafy(ccyFormat(contract.total_estimated_cost))}</TableCell>
    //         <TableCell align="right">${commafy(ccyFormat(contract.markup))}</TableCell>
    //         <TableCell align="right">{contract.markup_percentage !== null ? `${contract.markup_percentage.toFixed(2)} %` : 'N/A'}</TableCell>
    //       </TableRow>
    //     ))}
    //     <TableRow style={{ fontSize: '40pt', fontWeight: 'bold', borderTop: '2px solid black' }}>
    //         <TableCell> Total</TableCell>
    //         <TableCell align="right">${commafy(subtotal_sum(subtotals, "total_ntp_amount"))}</TableCell>
    //         <TableCell align="right">${commafy(subtotal_sum(subtotals, "total_estimated_cost"))}</TableCell>
    //         <TableCell align="right">${commafy(ccyFormat(subtotal_sum(subtotals, "markup")))}</TableCell>
    //         <TableCell align="right">{subtotal_average(subtotals, "markup_percentage") !== null ? `${subtotal_average(subtotals, "markup_percentage").toFixed(2)} %` : 'N/A'}</TableCell>
    //       </TableRow>
    //     </TableBody>
    //   </Table> */}
     </Box>
    );
    }