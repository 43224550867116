import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HelpOutline from '@mui/icons-material/HelpOutline';
import useAuthStore from '../auth/authStore';
import { useNavigate, useLocation } from 'react-router-dom';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LogoutIcon from '@mui/icons-material/Logout';

import { Menu, MenuItem, Avatar } from '@mui/material';

const TopBanner = () => {
  let navigate = useNavigate();
  const { isAuthenticated, username, role } = useAuthStore();
  const logOut = useAuthStore(state => state.logOut);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logOut(navigate);
    handleClose();
  };

    return (
    <AppBar className="TopBar" position="fixed" style={{ background: '#2E3B55', width: '100%'}}>
        <Toolbar style ={{ justifyContent: 'space-between'}}>
            <img src='./logo.png' alt="Company Logo" style={{ flexGrow: 0, maxWidth: '150px' }} />
            {isAuthenticated && (
          <Box sx={{display: 'flex'}}>
            <IconButton color="inherit">
                    <HelpOutline />
                </IconButton>

                <IconButton color="inherit">
                    <Badge badgeContent={0} color="secondary"> {/* badgeContent can be dynamic */}
                    <NotificationsIcon />
                    </Badge>
                </IconButton>
            <IconButton edge="end" color="inherit" onClick={handleClick}>
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem disabled style={{ color: 'inherit', opacity: 0.8, padding: 13}}>
                <Avatar style= {{marginRight:12}} /> {username}
              </MenuItem>
              <MenuItem disabled style={{ color: 'inherit', opacity: 0.8 }}>
                <AssignmentIndIcon style= {{marginRight:8}}/> Role: {role.toUpperCase()}
                </MenuItem>
              <MenuItem disabled style={{ height: 5 }} >
                <hr style={{ width: '100%', margin: 0 }} />
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <LogoutIcon style= {{marginRight:8}}/> 
                    Logout
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
    );
};

export default TopBanner;