import React, { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate, useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useAuthStore from './authStore';

const shakeKeyframes = `
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
  20%, 40%, 60%, 80% { transform: translateX(5px); }
}
`;

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mikinc.us/">
        MIK Construction, Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const Login = () =>{
    const location = useLocation();
    let navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showError, setShowError] = useState(false);
    const [shakeKey, setShakeKey] = useState(0);
    const logIn = useAuthStore(state => state.logIn);

    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

    useEffect(() => {
      if (isAuthenticated) {
        navigate(location.state?.from || '/proposals');
      }
    }, [isAuthenticated, navigate, location]);
    
    const login = async (event) => {
      event.preventDefault();
      setShowError(false);
      try {
        await logIn(username, password, navigate);
        setShowError(!isAuthenticated); // Assuming isAuthenticated gets updated on successful login
      } catch (error) {
        setTimeout(() => setShowError(true), 10); // Show error if login fails
        setShakeKey(prevKey => prevKey + 1);
        setTimeout(() => setShowError(false), 3000); // Hide error after 3 seconds
      }
    };
  

  return (
    <div style={{ 
      position: 'relative', // Needed for the pseudo-element to position correctly
      height: '100vh',
      overflow: 'hidden', // Ensures the blur doesn't spill outside the div
    }}>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundImage: `url(/images/construction.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        filter: 'blur(3px)', // Adjust the blur level as needed
      }}></div>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.35)', // Adjust the opacity as needed
      }}></div>
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" style={{  position: 'relative', 
          zIndex: 2, 
          backgroundColor: 'white', 
          padding: '20px', 
          borderRadius: '10px',
          marginTop: '20vh',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)' }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'grey' }}>
            <LockOutlinedIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" onSubmit={login} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
              {showError && (
                <Typography color="red">
                  *Wrong username or password
                </Typography>
              )}
            <style>{shakeKeyframes}</style>
            <Button
              key={shakeKey}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, ...(showError ? { animation: 'shake 0.7s' } : {}) 
            }}
              onClick={login}
            >
              Login
            </Button>
            <Grid container justifyContent='center'>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    </div>
  );
}

export default Login