const fetchBidCounts = (config, setBidCounts) => {
    fetch(`${config.apiBaseUrl}/api/bids/status-count/`, { credentials: 'include' }) // changed to 'include' to send cookies in a cross-origin resource sharing (CORS) request
      .then(response => response.json())
      .then(data => {
        let updatedStatus = {};

        // Iterate over each status in the data and update the count
        data.forEach(dataStatus => {
          updatedStatus[dataStatus.bid_result] = dataStatus.num_bids;
        });
        
        setBidCounts(prevBidCounts => ({
          ...prevBidCounts, 
          ...updatedStatus,
        }));
      })      
      .catch(error => {
        console.error('Error fetching BidCounts data:', error);
      });
  };
  
  export default fetchBidCounts;
  