import React from 'react';
import { Grid, Stack, Typography, Avatar } from '@mui/material';
import MainCard from '../MainCard';
import Box from '@mui/material/Box';

function staticValueToColor(value) {
    const color_map = {
        "PUBLIC": "hsl(210, 60%, 70%)",
        "PRIVATE": "hsl(120, 40%, 70%)", 
        "JOC/IDIQ": "hsl(30, 40%, 70%)"       
    };
    return color_map[value] || "hsl(0, 0%, 80%)";
  }

const ResultCounts = ({ status, bidCount, categoryCount, statusMapping }) => (
  <MainCard contentSX={{ p: 2.25 }} sx={{
  bgcolor: 'rgba(250, 250, 250, 0.5)', // slightly off-white background
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // drop shadow
  borderRadius: '4px' 
  }}>
  {/* Card content */}
    <Stack spacing={1.5}>
      <Typography variant="h6" color="textSecondary">
        {statusMapping[status]}: {bidCount}
      </Typography>
      <Grid container alignItems="center">
        <Grid item>
          {/* <Typography variant="h4" color="inherit">
            {projectCount}
          </Typography> */}
        </Grid>
          <Grid item>            
          </Grid>
      </Grid>
    </Stack>
    {/* <Typography variant="caption" color="textSecondary">
        Proposals per Estimator:{' '}
        <Typography component="span" variant="caption" sx={{ color: `${'primary' || 'test' }.main` }}>
        </Typography>{' '}
      </Typography> */}
      {bidCount > 0
          ? (
        <Box sx={{ pt: 1.25 }}>
        {
          Object.entries(categoryCount || {} ).map(([key,value]) => {
            const initials = key.split(' ').map((n)=>n[0]).join('').toUpperCase();
          return (
            <Grid container alignItems="center" key={key}>
            <Avatar  sx={{ bgcolor: staticValueToColor(key)}}>              
              {initials}
            </Avatar>
            <Grid padding={1.2}>
              <Typography variant="h8" color="textSecondary"> {key}: {value} </Typography>
            </Grid>
          </Grid>
          );
          })
        }
        </Box>
            ) :  null // Empty state, including when projectCount is 0
      }
  </MainCard>
);


export default ResultCounts