// BetweenFilter.js
import React from 'react';
import { TextField } from '@mui/material';
import { useGridRootProps } from '@mui/x-data-grid'
import SyncIcon from '@mui/icons-material/Sync';

export const betweenDateFilterOperator = {
    label: 'Between',
    value: 'between',
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      const [start, end] = filterItem.value.map(dateStr => dateStr ? new Date(dateStr) : null);
      if (start == null || end == null) {
        return null;
      }
      return ({ value }) => {
        const dateValue = value ? new Date(value) : null;
        return dateValue != null && start <= dateValue && dateValue <= end;
      };
    },
    InputComponent: BetweenDateInputComponent, // Ensure this component handles date inputs correctly
  }

function BetweenDateInputComponent(props) {
  const rootProps = useGridRootProps();
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = React.useRef();
  const [filterValueState, setFilterValueState] = React.useState(item.value ?? ['', '']);
  const [applying, setIsApplying] = React.useState(false);

  React.useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  React.useEffect(() => {
    const itemValue = item.value ?? ['', ''];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (start, end) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([start, end]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: [start, end] });
    }, rootProps.filterDebounceMs);
  };

  const handleEndFilterChange = (event) => {
    const newEnd = event.target.value;
    updateFilterValue(filterValueState[0], newEnd);
  };

  const handleStartFilterChange = (event) => {
    const newStart = event.target.value;
    updateFilterValue(newStart, filterValueState[1]);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <TextField
        name="start-date-input"
        placeholder="Start Date"
        label="Start Date"
        variant="standard"
        value={filterValueState[0]}
        onChange={handleStartFilterChange}
        type="date"
        inputRef={focusElementRef}
        InputLabelProps={{ shrink: true }}
        sx={{ minWidth: 120}}
      />
      <TextField
        name="end-date-input"
        placeholder="End Date"
        label="End Date"
        variant="standard"
        value={filterValueState[1]}
        onChange={handleEndFilterChange}
        type="date"
        InputLabelProps={{ shrink: true }}
        InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
        sx={{ minWidth: 120}}
      />
    </div>
  );
}

export default betweenDateFilterOperator;
