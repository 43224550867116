import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import useAuthStore from './authStore';

function PrivateRoute({ element, ...rest }) {
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);
  const loading = useAuthStore(state => state.loading);
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  } else if (!isAuthenticated) {
    return <Navigate to="/" replace state={{ from: location }} />;
  } else {
    return element;
  }
}

export default PrivateRoute;