import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid,  GridColumnMenu, gridFilteredSortedRowIdsSelector, gridVisibleColumnFieldsSelector, useGridApiContext, GridToolbarContainer } from '@mui/x-data-grid';
import { Alert, Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Snackbar, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import CustomToolbar from './Excel';

import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FunctionsIcon from '@mui/icons-material/Functions';
import config from '../config.json';

const fieldNames = ['first_name', 'last_name', 'email', 'phone_number']

const fields = {id: null, first_name: '', last_name: '', email: '', phone_number: ''}

const fieldLabels = [{name: 'first_name', label: 'First Name' }, {name: 'last_name', label: 'Last Name' }, {name: 'email', label: 'Email' }, {name: 'phone_number', label: '10 Digit Phone Number' } 
]

function RowAddTextField({ name, label, autoFocus, value, onChange }) {
    return (
      <TextField
        autoFocus={autoFocus}
        margin="dense"
        name={name}
        label={label}
        type="text"
        fullWidth
        value={value || ''}
        onChange={onChange}
      />
    );
  }

function PeopleComponent() {
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState(fields);
    const [editedRows, setEditedRows] = useState({});
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [snackbar, setSnackbar] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try{
        const peopleResponse = await axios.get(`${config.apiBaseUrl}/api/people/`, {withCredentials: true});

        const people = peopleResponse.data;

        const formattedPeople = people.map((person) => ({
        id: person.id,
        ...person,
        }));
        
        setRows(formattedPeople);
        setLoading(false);
        } catch (error) {
            console.error('Error fetching data: ', error);
    };
};
    
        fetchData();
      }, []);
    
    useEffect(() => {
    }, [editedRows]);

    const columns = [
        { 
            headerName: "First Name", 
            field: "first_name",
            minWidth: 125,
            editable: true
        },
        { 
            headerName: "Last Name", 
            field: "last_name",
            minWidth: 165,
            editable: true
        },
        { 
            headerName: "Email", 
            field: "email",
            minWidth: 200,
            editable: true  
        },
        { 
            headerName: "Phone Number", 
            field: "phone_number",
            minWidth: 200,
            editable: true,
            valueParser: (value) => {
                const cleanValue = value ? value.replace(/\D/g, '') : '';
                return cleanValue;
              },
              valueFormatter: (params) => {
                let cleanNumber = params.value ? params.value.toString().replace(/\D/g, '') : '';
                if (cleanNumber.length > 10 && cleanNumber.startsWith('1')) {
                  cleanNumber = cleanNumber.substr(1);
                }
                return cleanNumber ? `+1 (${cleanNumber.substr(0, 3)}) ${cleanNumber.substr(3, 3)}-${cleanNumber.substr(6)}` : '';
              },
        }
          ];

    // Form for New Row Confirmation
    const handleOpenNewRow = () => {
        setForm(fields);
        setOpen(true);
    };

    const handleCloseNewRow = () => {
        setOpen(false);
        setForm(fields);
    };

    // Dialogue for Delete Confirmation
    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true);
        };
      
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        };

    // Dialogue for Edit Confirmation
    const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
    };
  
    // Function to close the dialog
    const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    };

    const handleCloseSnackbar = () => setSnackbar(null);

    const handleCSVFileLoaded = async (data) => {
      const acceptedHeaders = ["First Name",
        "Last Name",
        "Email", 
        "Phone Number"];

      const headerToFieldMap = Object.fromEntries(
          acceptedHeaders.map((header, index) => [header, fieldNames[index]])
      );

      const contractIdKey = headerToFieldMap["Contract ID"] || "Contract ID";

      const payload = data.map(row => {
          return Object.keys(row).reduce((newRow, key) => {
              if (acceptedHeaders.includes(key)) {
                  // Use the headerToFieldMap to find the new key for this column
                  const newKey = headerToFieldMap[key];
                  newRow[newKey] = row[key];
                }
                return newRow;
            }, {});
          }).filter(row => row[contractIdKey] && row[contractIdKey].trim() !== "");

          try {
            const response = await axios.post(`${config.apiBaseUrl}/api/projects/upload_projects/`, JSON.stringify(payload), {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const highestId = Math.max(...rows.map((row) => row.id), 0);
            const newRows = response.data.new_rows.map((item, index) => ({
                id: highestId + index + 1,
                ...item,
            }));
    
            setRows((prev) => [
                ...prev,
                ...newRows
            ]);
    
            setSnackbar({ children: 'Successfully created new rows', severity: 'success' });
    
        } catch (error) {
            setSnackbar({ children: 'Submit went wrong', severity: 'error' });
            console.error('Error:', error);
        }
    };

    const handleFileLoaded = async (data) => {

        const acceptedHeaders = ["First Name",
        "Last Name",
        "Email", 
        "Phone Number"];

        const headerToFieldMap = Object.fromEntries(
            acceptedHeaders.map((header, index) => [header, fieldNames[index]])
          );

        if (!data[0]) {
            setSnackbar({ children: 'Invalid file format: Missing headers', severity: 'error' });
            return;
        }

        // clean end of line \r values
        let cleanedHeaders = data[0].map(header => header.replace(/\r?\n|\r/g, ''));
        let cleanedData = data.map(row => 
            Array.from({ length: row.length }, (_, i) => {
                const field = row[i];
                return field === undefined ? null : 
                       (typeof field === 'string' ? field.replace(/\r?\n|\r/g, '') : field);
            })
        );

       // Create a list of valid header indices
        const validIndices = cleanedHeaders.map((header, index) => acceptedHeaders.includes(header) ? index : null).filter(index => index !== null);
        const validFieldNames = cleanedHeaders.map(header => headerToFieldMap[header]).filter(Boolean);

        // Use validIndices to filter both headers and data
        cleanedHeaders = cleanedHeaders.filter((_, index) => validIndices.includes(index));
        cleanedData = cleanedData.map(row => row.filter((_, index) => validIndices.includes(index)));

        // Check if any valid headers are left
        if (cleanedHeaders.length === 0) {
            setSnackbar({ children: 'Invalid file format: No matching columns found', severity: 'error' });
            return;
        }

        // Exclude the header row and zip rows into dictionaries
        const payload = cleanedData.slice(1).map(row => {
            return Object.fromEntries(row.map((cell, index) => [validFieldNames[index], cell]));
        });

        // const convertDateFormat = (dateStr) => {
        //     return moment(dateStr).format('YYYY-MM-DD');
        // };

        // payload.forEach((row) => {
        //     if (row['job_walk_completion'] === "" || row['job_walk_completion'] === null){
        //         row['job_walk_completion'] = false
        //     }
        //     statusFields.forEach(field=> {
        //         if (row[field] === "" || row[field] == null) {
        //             row[field] = "NS";
        //           }
        //         else {
        //             row[field] = statusMapping[row[field]]
        //         }
        //     });
        //     peopleFields.forEach(field=> {
        //         if (row[field]) {
        //             row[field] = reversedPeopleMapping[row[field]];
        //           }
        //         });
        //     dateFields.forEach(field => {
        //         if (row[field]) {
        //             row[field] = convertDateFormat(row[field]);
        //         }
        //     });
        // });

        try {
            const response = await axios.post(`${config.apiBaseUrl}/api/people/upload_people/`, JSON.stringify(payload), {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const highestId = Math.max(...rows.map((row) => row.id), 0);
            const newRows = response.data.new_rows.map((item, index) => ({
                id: highestId + index + 1,
                ...item,
            }));
    
            setRows((prev) => [
                ...prev,
                ...newRows
            ]);
    
            setSnackbar({ children: 'Successfully created new rows', severity: 'success' });
    
        } catch (error) {
            setSnackbar({ children: 'Submit went wrong', severity: 'error' });
            console.error('Error:', error);
        }
    };
    function findDifference(dict1, dict2) {
        const difference = {};
      
        // Iterate over keys of dict1
        for (const key in dict1) {
          // Check if the values are not equal
          if (dict1[key] !== dict2[key]) {
            difference[key] = dict1[key];
          }
        }
      
        return difference;
      }
    
      function updateDictionary(dict, difference) {
        const updatedDict = { ...dict };

        for (const key in difference) {
            if (difference.hasOwnProperty(key)) {
          updatedDict[key] = difference[key];
        }
      }
      return updatedDict
    }

    const handleEditCellChange = useCallback(
        (params, params2) => {
            if (typeof params !== 'object' || params === null || !params.hasOwnProperty('id')) {
                console.error("Invalid params passed to handleEditCellChange");
                return;
            }

            const difference = findDifference(params, params2)
            const updatedRows = editedRows ? { ...editedRows} : {};
            const id = params.id
            updatedRows[id] = updatedRows[id] || {};
            updatedRows[id]["id"] = id
            for (let field in difference) {
                if (field === 'id') continue;
                updatedRows[id][field] = difference[field];
            }
        setEditedRows(updatedRows)

        return updatedRows[params.id];
    },
        [editedRows]
      );

    const handleProcessRowUpdateError = React.useCallback((error) => {
            setSnackbar({ children: 'row update went wrong', severity: 'error' });
          }, []);

    const handleInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value || null,
        });
    };

    const handleSubmit = () => {
        const pattern = /^\d{10}$/;
        if ((form['phone_number'] !== null && form['phone_number'] !== '')) {
          if (!pattern.test(form['phone_number'])) {
              handleCloseNewRow();
              setSnackbar({ children: 'Not a valid phone number', severity: 'error' });
              return;
              }
          
          if (!form['phone_number'].startsWith('+1')) {
              form['phone_number'] = `+1${form['phone_number']}`;
              }
          }

        axios.post(`${config.apiBaseUrl}/api/people/`, form, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }            
        })
        .then((response) => {
        setSnackbar({ children: 'Successfully created new row', severity: 'success' });
        const highestId = Math.max(...rows.map((row) => row.id), 0);
        setRows((prev) => [
            ...prev,
            {
            id: highestId + 1,
            ...response.data,
            },
        ]);
        })
        .catch((error) => {
        setSnackbar({ children: 'Submit went wrong', severity: 'error' });
        console.error('Error:', error);
        });

    handleCloseNewRow();
    };
    

    const handleConfirmEdit = () => {
        selectedRows.forEach((id) => {
            const rowChanges = editedRows[id];
            const originalRow = rows.find(dict => dict.id === id);
            const url = `${config.apiBaseUrl}/api/people/${id}/`;
             // Ensure we found a matching row
            if (!rowChanges) {
                console.error(`Could not find row with id ${id} in current state.`);
                return;
            }

            const updatedRow = updateDictionary(originalRow, rowChanges)
            for (let field in updatedRow) {
                if (field === 'id') continue;

                if (field === 'phone_number') {
                    const pattern = /^\d{10}$/;
                    if ((updatedRow[field] !== null && updatedRow[field] !== '')) {
                      if (!pattern.test(updatedRow[field])) {
                          handleCloseEditDialog();
                          setSnackbar({ children: 'Not a valid phone number', severity: 'error' });
                          return;
                        }
                      if (!updatedRow['phone_number'].startsWith('+1')) {
                          updatedRow['phone_number'] = `+1${updatedRow['phone_number']}`;
                        }
                      }
                  }
            }
            axios.put(url, updatedRow, {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                }
              })
                .then((response) => {
                setSnackbar({ children: 'Successfully saved changes', severity: 'success' });
                setRows((prev) =>
                prev.map((row) => {
                    if (row.id !== id) {
                    return row;
                    }

                    return { ...row, ...response.data };
                })
                );
            })
            .catch((error) => {
                setSnackbar({ children: 'Confirm edit went wrong', severity: 'error' });
                console.error('Error:', error);
            });
        handleCloseEditDialog();
    });
    }
    
    const handleDelete = () => {
        axios.post(`${config.apiBaseUrl}/api/people/bulk-delete/`, { ids: selectedRows }, { withCredentials: true })
          .then(() => {
            setRows((prev) => prev.filter((row) => !selectedRows.includes(row.id)));
            setSnackbar({ children: 'Successfully deleted row(s)', severity: 'success' });
          })
          .catch((error) => {
            setSnackbar({ children: 'Delete went wrong', severity: 'error' });
            console.error('Error:', error);
          });
      
        handleCloseDeleteDialog();
      };

    
    function AggregationMenuItem(props) {
        const { aggregationHandler, columnName } = props;
        return (
            <MenuItem onClick={() => aggregationHandler(columnName)}>
            <ListItemIcon>
                <FunctionsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{`Toggle Aggregation`}</ListItemText>
            </MenuItem>
        );
    }

    function CustomColumnMenu(props) {
        return (
          <GridColumnMenu
            {...props}
            slots={{
                columnMenuUserItem: AggregationMenuItem
              }}
              slotProps={{
                columnMenuUserItem: {
                  displayOrder: 15,
                  columnName: props.colDef.field,
                  aggregationHandler: handleToggleAggregation
                },
              }}
            />
          );
        }

      const handleToggleAggregation = (field) => {
        setSelectedColumns((prevSelectedColumns) =>
          prevSelectedColumns.includes(field)
            ? prevSelectedColumns.filter((col) => col !== field)
            : [...prevSelectedColumns, field]
        );
      };
      

    const calculateSum = (rows, field) => {
        return rows.reduce((acc, row) => acc + parseFloat(row[field] || 0), 0);
    };
    
    const AggregatorToolbar = () => {
        const apiRef = useGridApiContext();
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const visibleColumns = gridVisibleColumnFieldsSelector(apiRef);

        const filteredRows = filteredSortedRowIds.map((id) => {
            const row = apiRef.current.getRow(id);
            return Object.keys(row)
              .filter((key) => visibleColumns.includes(key))
              .reduce((obj, key) => {
                obj[key] = row[key];
                return obj;
              }, {});
          });

        const sums = selectedColumns.reduce((acc, column) => {
            acc[column] = calculateSum(filteredRows, column);
            return acc;
          }, {}); // Using the calculateSum function
    
    return (
        <GridToolbarContainer>
      <Box display="flex" justifyContent="space-between" width="100%">
        {/* First toolbar section (e.g., your existing toolbar customizations) */}
        <div>
        <CustomToolbar onCSVFileLoaded={handleCSVFileLoaded} onFileLoaded={handleFileLoaded} />
        </div>
        </Box>
        {/* Second row with the aggregations */}
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            {visibleColumns.map((column) => (
            <Box key={column} flexBasis={0} flexGrow={1} maxWidth="100%">
                {selectedColumns.includes(column) && (
                <Typography variant="body2">
                    {`Sum of ${column}: ${sums[column]}`}
                </Typography>
                )}
            </Box>
            ))}
        </Box>
    </GridToolbarContainer>
  );
};


// STYLING
    const theme = createTheme({
      palette: {
        neutral: {
          main: '#64748B',
          contrastText: '#fff',
        },
        secondary: {
          light: '#C5E3EC',
          main: '#1AA7EC',
          dark: '#AADDEC',
          contrastText: '#000',
        },
      },
      typography: {
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },
    });
    if (loading) return (<ThemeProvider theme={theme}>
        <Box sx={{display: 'flex'}}
        minHeight="50vh" alignItems="center"
        justifyContent="center">
        <CircularProgress sx = {{color:'red'}}/>
        </Box>
        </ThemeProvider>
        );     

    return (
        <>

        {/* Interactive Buttons */}
            <div style={{ margin: '0px' }}>
            <ThemeProvider theme={theme}>
                <div style={{ marginTop: '10vh' }}>
                <div style={{
                        display: 'flex', // Enables flexbox
                        justifyContent: 'flex-end', // Aligns children (the buttons) to the right
                        width: 'calc(85%)',
                        height: '10%', // Full height of the container (adjust as needed)
                        padding: '5px', // Adds some space inside the container's edges
                        }}>       
                    <Button
                        sx={{ mx: 0.3 }}
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={handleOpenNewRow}
                        >
                        Add
                    </Button>     
                    <Button 
                        sx={{ mx: 0.3 }}
                        variant ="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={handleOpenDeleteDialog}>
                            Delete
                    </Button>
                    <Button 
                        sx={{ mx: 0.3 }}
                        variant ="contained"
                        color="secondary"
                        startIcon={<EditIcon />}
                        onClick={handleOpenEditDialog}>
                            Save Edits
                    </Button>
                </div>

        {/* Add New Row Form */}
            <Dialog open={open} onClose={handleCloseNewRow}>
                <DialogTitle>Add new row</DialogTitle>
                <DialogContent>
                {fieldLabels.map((field) => (
                <RowAddTextField
                    key={field.name}
                    autoFocus
                    name={field.name}
                    label={field.label}
                    value={form[field.name]}
                    onChange={handleInputChange}
                />
                ))}      
                
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewRow}>Cancel</Button>
                    <Button onClick={handleSubmit}>Add</Button>
                </DialogActions>
            </Dialog>   
            
            {/* Edit Confirmation Box  */}
            <Dialog
                open={editDialogOpen}
                onClose={handleCloseEditDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Commit Changes?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Do you confirm to edit these rows?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                    No
                    </Button>
                    <Button onClick={handleConfirmEdit} color="primary" autoFocus>
                    Yes
                    </Button>
                </DialogActions>
                </Dialog>

            
            {/* Delete Confirmation Box  */}

            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Delete Rows?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Do you confirm to delete these rows?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                    No
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                    Yes
                    </Button>
                </DialogActions>
                </Dialog>

                {/* Data Grid Parameters */}
                <Box>
                    <h2> </h2>

                </Box>
                <Box sx={{ minHeight: '50%', minWidth: '45%', maxHeight:'85vh', maxWidth: '75vw', height: 'auto', width: 'auto', marginLeft: '3vw', marginRight: '5vw',  marginTop: '2vh', marginBottom: '2vh' }}>
                    <DataGrid
                        // editmode="row"
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        slots={{ columnMenu: (props) => <CustomColumnMenu {...props} toggleAggregation={handleToggleAggregation} />,
                                toolbar: AggregatorToolbar
                        }}
                        slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                        }}
                        processRowUpdate={(updatedRow, originalRow) =>
                          handleEditCellChange(updatedRow, originalRow)
                        }
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setSelectedRows(newRowSelectionModel);
                        }}
                        initialState={{
                            ...rows.initialState,
                            columns: {
                                ...rows.initialState?.columns,
                                columnVisibilityModel: {
                                id: false
                                },
                            },
                            }}
                            sx={{fontFamily: [
                              '-apple-system',
                              'BlinkMacSystemFont',
                              '"Segoe UI"',
                              'Roboto',
                              '"Helvetica Neue"',
                              'Arial',
                              'sans-serif',
                              '"Apple Color Emoji"',
                              '"Segoe UI Emoji"',
                              '"Segoe UI Symbol"',
                            ].join(','),
                            WebkitFontSmoothing: 'auto',
                            letterSpacing: 'normal',
                          color: "#003665",
                          '& .MuiDataGrid-columnsContainer': {
                              backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
                            },
                            '& .MuiDataGrid-iconSeparator': {
                              display: 'none',
                            },
                            '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                              borderRight: `1px solid ${
                                theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                              }`,
                            },
                            '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                              borderBottom: `1px solid ${
                                theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                              }`,
                            },
                            '& .MuiDataGrid-cell': {
                              color:
                                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
                            },
                            '& .MuiPaginationItem-root': {
                              borderRadius: 0,
                            },}}
                    />
                </Box>
                </div>
                {!!snackbar && (
                    <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </ThemeProvider>
                
            </div>
            
            
    </>
    );
}

export default PeopleComponent;