import { create } from 'zustand';
import axios from 'axios';
import config from '../config.json';

const useAuthStore = create(set => ({
  isAuthenticated: false,
  loading: false,
  username: localStorage.getItem('username') || '',
  role: localStorage.getItem('role') || '',
  logIn: async (username, password, navigate) => {
    set({ loading: true, error: null });
    try {
      const response = await axios.post(`${config.apiBaseUrl}/api/auth/login/`, {
        username,
        password,
      });

    // Update the application state to reflect that the user is authenticated
    if (response.status === 200){
        localStorage.setItem('username', username);
        set({ isAuthenticated: true, loading: false, username: username});

        try{
          const roleResponse = await axios.get(`${config.apiBaseUrl}/api/auth/role/`);
          localStorage.setItem('role', roleResponse.data.role);
          set({ role: roleResponse.data.role });
          } catch (error) {
            set({ error: error.message, loading: false });
          }
        navigate('/proposals');
      }
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },
  logOut: async (navigate) => {
    try {
      // Invalidate the session in the backend
      await axios.post(`${config.apiBaseUrl}/api/auth/logout/`, { withCredentials: true })
      .then(response => {
        navigate('/');
        localStorage.removeItem('username');
        localStorage.removeItem('role');
        set({ isAuthenticated: false, username: '', role: '' });
      })
      .catch(error => {
        console.error('Logout error:', error);
      });
    } catch (error) {
    }
    },
  setAuthState: (auth, loading) => set(() => ({ isAuthenticated: auth, loading: loading }))
}));

export default useAuthStore