const fetchContractSums = (config, setContractorSums) => {
    fetch(`${config.apiBaseUrl}/api/projects/contract-sums/`, { credentials: 'include' }) // changed to 'include' to send cookies in a cross-origin resource sharing (CORS) request
      .then(response => response.json())
      .then(data => {
        let fetchedDataObject = {};

        // Iterate over each status in the data and update the count
        data.forEach(item => {
            fetchedDataObject[item.contract_id] = item;
          });
      
          setContractorSums(prevProjectCounts => {
            let updatedSums = {...prevProjectCounts};
      
            // Iterate over the keys of the previous state
            Object.keys(updatedSums).forEach(contract_id => {
              if (fetchedDataObject[contract_id]) {
                // Update existing entries
                updatedSums[contract_id] = fetchedDataObject[contract_id];
              } else {
                // Remove entries that are not present in the new data
                delete updatedSums[contract_id];
              }
            });
      
            // Add new entries that were not in the previous state
            Object.keys(fetchedDataObject).forEach(contract_id => {
              if (!updatedSums[contract_id]) {
                updatedSums[contract_id] = fetchedDataObject[contract_id];
              }
            })
            return updatedSums;
        })
    })      
      .catch(error => {
        console.error('Error fetching ProjectCounts data:', error);
      });
  };
  
  export default fetchContractSums;
  